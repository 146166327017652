import cookies from 'js-cookie'
import { parseDomain } from 'parse-domain'
import graph_en_us from './graph_en_us.json'
import graph_id_id from './graph_id_id.json'
import texts from './texts.json'

const Coordinator = (options) => (handler) => {
  if (!options.url) {
    throw Error('some coordinator options are missing')
  }

  const url = options.url
  const signedInKey = `${new URL(url).hostname}_signedIn`

  // TODO: eslint?
  const loading = ValueNotifier({
    seed: {},
  })
  const failure = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const failureAssociation = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const success = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const redirect = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const lexicon = ValueNotifier({
    seed: lexicons[localStorage.getItem('language') ?? 'en-US'],
  })
  const language = ValueNotifier({
    seed: localStorage.getItem('language') ?? 'en-US',
  })
  const graph = ValueNotifier({
    seed: localStorage.getItem('language') === 'id-ID'
      ? graph_id_id : graph_en_us,
  })
  const otpRenewalResult = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const signedIn = ValueNotifier({
    seed: cookies.get(signedInKey),
  })
  const userInfo = ValueNotifier({
    seed: null,
  })
  const creditTypes = ValueNotifier({
    seed: [],
  })
  const sdgs = ValueNotifier({
    seed: [],
  })
  const mechanisms = ValueNotifier({
    seed: [],
  })
  const sectors = ValueNotifier({
    seed: [],
  })
  const ownedProjectAppealSummaries = ValueNotifier({
    seed: [],
  })
  const ownedProjectAppeal = ValueNotifier({
    seed: null,
  })
  const projectSubmissionResult = ValueNotifier({
    renotify: false,
  })
  const ownedProjectSummaries = ValueNotifier({
    seed: [],
  })
  const ownedProject = ValueNotifier({
    seed: null,
  })
  const listedProjectSummaries = ValueNotifier({
    seed: [],
  })
  const listedProject = ValueNotifier({
    seed: null,
  })
  const ownedBookingSummaries = ValueNotifier({
    seed: [],
  })
  const ownedEligibilityCheckSummaries = ValueNotifier({
    seed: [],
  })
  const ownedEligibilityCheckDetail = ValueNotifier({
    seed: null,
  })

  window.cookieStore?.addEventListener('change', async function ({
    changed: changedCookies,
    deleted: deletedCookies,
  }) {
    if (changedCookies.some(cookie =>
      cookie.name === signedInKey &&
      cookie.value === 'true'
    )) {
      signedIn.value = cookies.get(signedInKey) === 'true'
      requestUserInfoLookup()
    }

    if (deletedCookies.some(cookie =>
      cookie.name === signedInKey
    )) {
      requestUserTokenDisposal()
    }
  })

  async function requestLanguageChange(request) {
    loading.value = {
      ...loading.value,
      requestLanguageChange: true,
    }

    if (lexicons[request]) {
      lexicon.value = lexicons[request]
      graph.value = lexicon.value.id === 'id-ID' ? graph_id_id : graph_en_us
      language.value = request
      localStorage.setItem('language', request)
    }

    loading.value = {
      ...loading.value,
      requestLanguageChange: false,
    }
  }

  async function requestUserRegistration(request) {
    loading.value = {
      ...loading.value,
      requestUserRegistration: true,
    }

    const response = await handler.requestUserRegistration({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      redirect.value = '/login'
    }

    loading.value = {
      ...loading.value,
      requestUserRegistration: false,
    }
  }

  async function requestUserTokenRenewal(request) {
    loading.value = {
      ...loading.value,
      requestUserTokenRenewal: true,
    }

    const response = await handler.requestUserTokenRenewal({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      localStorage.setItem('token', response.diff.token)
      signedIn.value = response.diff.token
      redirect.value = request.redirect ?? '/'
    }

    loading.value = {
      ...loading.value,
      requestUserTokenRenewal: false,
    }
  }

  async function requestUserOtpRenewal(request) {
    loading.value = {
      ...loading.value,
      requestUserOtpRenewal: true,
    }

    const response = await handler.requestUserOtpRenewal({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      otpRenewalResult.value = true
    }

    loading.value = {
      ...loading.value,
      requestUserOtpRenewal: false,
    }
  }

  async function requestUserPasswordRenewal(request) {
    loading.value = {
      ...loading.value,
      requestUserPasswordRenewal: true,
    }

    const response = await handler.requestUserPasswordRenewal({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      redirect.value = '/login'
    }

    loading.value = {
      ...loading.value,
      requestUserPasswordRenewal: false,
    }
  }

  async function requestUserInfoLookup() {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestUserInfoLookup: true,
      }

      const response = await handler.requestUserInfoLookup()

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        userInfo.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestUserInfoLookup: false,
      }
    }
  }

  async function requestCreditTypesLookup() {
    loading.value = {
      ...loading.value,
      requestCreditTypesLookup: true,
    }

    const response = await handler.requestCreditTypesLookup({
      token: signedIn.value,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      creditTypes.value = response.data
    }

    loading.value = {
      ...loading.value,
      requestCreditTypesLookup: false,
    }
  }

  async function requestSdgsLookup() {
    loading.value = {
      ...loading.value,
      requestSdgsLookup: true,
    }

    const response = await handler.requestSdgsLookup()

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      sdgs.value = response.data
    }

    loading.value = {
      ...loading.value,
      requestSdgsLookup: false,
    }
  }

  async function requestMechanismsLookup() {
    loading.value = {
      ...loading.value,
      requestMechanismsLookup: true,
    }

    const response = await handler.requestMechanismsLookup()

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      mechanisms.value = response.data
    }

    loading.value = {
      ...loading.value,
      requestMechanismsLookup: false,
    }
  }

  async function requestSectorsLookup() {
    loading.value = {
      ...loading.value,
      requestSectorsLookup: true,
    }

    const response = await handler.requestSectorsLookup()

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      sectors.value = response.data
    }

    loading.value = {
      ...loading.value,
      requestSectorsLookup: false,
    }
  }

  async function requestProjectAppealSubmission(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestProjectAppealSubmission: true,
      }

      const response = await handler.requestProjectAppealSubmission({
        ...request,
        token: signedIn.value,
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        redirect.value = '/owned-inquiries'
      }

      loading.value = {
        ...loading.value,
        requestProjectAppealSubmission: false,
      }
    }
  }

  async function requestOwnedProjectAppealSummariesLookup() {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestOwnedProjectAppealSummariesLookup: true,
      }

      const response = await handler.requestOwnedProjectAppealSummariesLookup({
        token: signedIn.value
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        ownedProjectAppealSummaries.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestOwnedProjectAppealSummariesLookup: false,
      }
    }
  }

  async function requestOwnedProjectAppealLookup(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestOwnedProjectAppealLookup: true,
      }

      const response = await handler.requestOwnedProjectAppealLookup({
        ...request,
        token: signedIn.value
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        ownedProjectAppeal.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestOwnedProjectAppealLookup: false,
      }
    }
  }

  async function requestQuestionnaireSubmission(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestQuestionnaireSubmission: true,
      }

      const response = await handler.requestQuestionnaireSubmission({
        ...request,
        token: signedIn.value,
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      }

      loading.value = {
        ...loading.value,
        requestQuestionnaireSubmission: false,
      }
    }
  }

  async function requestProjectSubmission(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestProjectSubmission: true,
      }

      const response = await handler.requestProjectSubmission({
        ...request,
        token: signedIn.value,
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        projectSubmissionResult.value = {
          potentialOffset: response.diff.potentialOffset,
        }
      }

      loading.value = {
        ...loading.value,
        requestProjectSubmission: false,
      }
    }
  }

  async function requestOwnedProjectSummariesLookup(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestOwnedProjectSummariesLookup: true,
      }

      const response = await handler.requestOwnedProjectSummariesLookup({
        ...request,
        token: signedIn.value
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        ownedProjectSummaries.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestOwnedProjectSummariesLookup: false,
      }
    }
  }

  async function requestOwnedProjectLookup(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestOwnedProjectLookup: true,
      }

      const response = await handler.requestOwnedProjectLookup({
        ...request,
        token: signedIn.value
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        ownedProject.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestOwnedProjectLookup: false,
      }
    }
  }

  async function requestListedProjectSummariesLookup(request) {
    loading.value = {
      ...loading.value,
      requestListedProjectSummariesLookup: true,
    }

    const response = await handler.requestListedProjectSummariesLookup({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      listedProjectSummaries.value = response.data
    }

    loading.value = {
      ...loading.value,
      requestListedProjectSummariesLookup: false,
    }
  }

  async function requestListedProjectLookup(request) {
    listedProject.value = null

    loading.value = {
      ...loading.value,
      requestListedProjectLookup: true,
    }

    const response = await handler.requestListedProjectLookup({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
      failureAssociation.value = response.association
    } else {
      listedProject.value = response.data
    }

    loading.value = {
      ...loading.value,
      requestListedProjectLookup: false,
    }
  }

  async function requestProjectBooking(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestProjectBooking: true,
      }

      const response = await handler.requestProjectBooking({
        ...request,
        token: signedIn.value
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        success.value = 'project booking successfully requested'
        requestListedProjectLookup({
          id: request.projectId
        })
      }

      loading.value = {
        ...loading.value,
        requestProjectBooking: false,
      }
    }
  }

  async function requestOwnedBookingSummariesLookup(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestOwnedBookingSummariesLookup: true,
      }

      const response = await handler.requestOwnedBookingSummariesLookup({
        ...request,
        token: signedIn.value,
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        ownedBookingSummaries.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestOwnedBookingSummariesLookup: false,
      }
    }
  }

  async function requestProjectFunding(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestProjectFunding: true,
      }

      const response = await handler.requestProjectFunding({
        ...request,
        token: signedIn.value
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        success.value = [
          'project funding successfully requested,',
          'we\'ll be in touch!',
        ].join('\n')
        requestListedProjectLookup({
          id: request.projectId
        })
      }

      loading.value = {
        ...loading.value,
        requestProjectFunding: false,
      }
    }
  }

  async function requestEligibilityCheckDrafting(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestEligibilityCheckDrafting: true,
      }

      const response = await handler.requestEligibilityCheckDrafting({
        ...request,
        token: signedIn.value,
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        success.value = 'eligibility check successfully drafted'
        // redirect.value = '/eligibility-check-submission/owned'
      }

      loading.value = {
        ...loading.value,
        requestEligibilityCheckDrafting: false,
      }
      
      return response;
    }
  }

  async function requestEligibilityCheckSubmission(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestEligibilityCheckSubmission: true,
      }

      const response = await handler.requestEligibilityCheckSubmission({
        ...request,
        token: signedIn.value,
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        success.value = 'eligibility check successfully submitted'
        redirect.value = '/eligibility-check-submission/owned'
      }

      loading.value = {
        ...loading.value,
        requestEligibilityCheckSubmission: false,
      }
    }
  }

  async function requestOwnedEligibilityCheckSummariesLookup(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestOwnedEligibilityCheckSummariesLookup: true,
      }

      const response = await handler.requestOwnedEligibilityCheckSummariesLookup({
        ...request,
        token: signedIn.value,
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
        failureAssociation.value = response.association
      } else {
        ownedEligibilityCheckSummaries.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestOwnedEligibilityCheckSummariesLookup: false,
      }
    }
  }

  async function requestOwnedEligibilityCheckDetailLookup(request) {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestOwnedEligibilityCheckDetailLookup: true,
      }

      const response = await handler.requestOwnedEligibilityCheckDetailLookup({
        ...request,
        token: signedIn.value,
      })

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
      } else {
        ownedEligibilityCheckDetail.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestOwnedEligibilityCheckDetailLookup: false,
      }
    }
  }

  async function requestUserTokenDisposal() {
    loading.value = {
      ...loading.value,
      requestUserTokenDisposal: true,
    }

    cookies.remove(signedInKey)
    const hostname = window.location.hostname
    const parsedDomain = parseDomain(hostname)
    const domain = parsedDomain.domain ?? ''
    const tlds = parsedDomain.topLevelDomains ?? []
    cookies.remove(signedInKey, {
      domain: [domain, ...tlds].join('.'),
    })
    signedIn.value = null

    loading.value = {
      ...loading.value,
      requestUserTokenDisposal: false,
    }
  }

  return {
    ...handler,
    requestLanguageChange,
    requestUserRegistration,
    requestUserTokenRenewal,
    requestUserOtpRenewal,
    requestUserPasswordRenewal,
    requestUserInfoLookup,
    requestCreditTypesLookup,
    requestSdgsLookup,
    requestMechanismsLookup,
    requestSectorsLookup,
    requestProjectAppealSubmission,
    requestOwnedProjectAppealSummariesLookup,
    requestOwnedProjectAppealLookup,
    requestQuestionnaireSubmission,
    requestProjectSubmission,
    requestOwnedProjectSummariesLookup,
    requestOwnedProjectLookup,
    requestListedProjectSummariesLookup,
    requestListedProjectLookup,
    requestProjectBooking,
    requestOwnedBookingSummariesLookup,
    requestProjectFunding,
    requestEligibilityCheckDrafting,
    requestEligibilityCheckSubmission,
    requestOwnedEligibilityCheckSummariesLookup,
    requestOwnedEligibilityCheckDetailLookup,
    requestUserTokenDisposal,
    subToLoading: loading.subscribe,
    unsubFromLoading: loading.unsubscribe,
    subToFailure: failure.subscribe,
    unsubFromFailure: failure.unsubscribe,
    subToFailureAssociation: failureAssociation.subscribe,
    unsubFromFailureAssociation: failureAssociation.unsubscribe,
    subToSuccess: success.subscribe,
    unsubFromSuccess: success.unsubscribe,
    subToLexicon: lexicon.subscribe,
    unsubFromLexicon: lexicon.unsubscribe,
    subToLanguage: language.subscribe,
    unsubFromLanguage: language.unsubscribe,
    subToGraph: graph.subscribe,
    unsubFromGraph: graph.unsubscribe,
    subToRedirect: redirect.subscribe,
    unsubFromRedirect: redirect.unsubscribe,
    subToOtpRenewalResult: otpRenewalResult.subscribe,
    unsubFromOtpRenewalResult: otpRenewalResult.unsubscribe,
    subToToken: signedIn.subscribe,
    unsubFromToken: signedIn.unsubscribe,
    subToUserInfo: userInfo.subscribe,
    unsubFromUserInfo: userInfo.unsubscribe,
    subToCreditTypes: creditTypes.subscribe,
    unsubFromCreditTypes: creditTypes.unsubscribe,
    subToSdgs: sdgs.subscribe,
    unsubFromSdgs: sdgs.unsubscribe,
    subToMechanisms: mechanisms.subscribe,
    unsubFromMechanisms: mechanisms.unsubscribe,
    subToSectors: sectors.subscribe,
    unsubFromSectors: sectors.unsubscribe,
    subToOwnedProjectAppealSummaries: ownedProjectAppealSummaries.subscribe,
    unsubFromOwnedProjectAppealSummaries: ownedProjectAppealSummaries.unsubscribe,
    subToOwnedProjectAppeal: ownedProjectAppeal.subscribe,
    unsubFromOwnedProjectAppeal: ownedProjectAppeal.unsubscribe,
    subToProjectSubmissionResult: projectSubmissionResult.subscribe,
    unsubFromProjectSubmissionResult: projectSubmissionResult.unsubscribe,
    subToOwnedProjectSummaries: ownedProjectSummaries.subscribe,
    unsubFromOwnedProjectSummaries: ownedProjectSummaries.unsubscribe,
    subToOwnedProject: ownedProject.subscribe,
    unsubFromOwnedProject: ownedProject.unsubscribe,
    subToListedProjectSummaries: listedProjectSummaries.subscribe,
    unsubFromListedProjectSummaries: listedProjectSummaries.unsubscribe,
    subToListedProject: listedProject.subscribe,
    unsubFromListedProject: listedProject.unsubscribe,
    subToOwnedBookingSummaries: ownedBookingSummaries.subscribe,
    unsubFromOwnedBookingSummaries: ownedBookingSummaries.unsubscribe,
    subToOwnedEligibilityCheckSummaries: ownedEligibilityCheckSummaries.subscribe,
    unsubFromOwnedEligibilityCheckSummaries: ownedEligibilityCheckSummaries.unsubscribe,
    subToOwnedEligibilityCheckDetail: ownedEligibilityCheckDetail.subscribe,
    unsubFromOwnedEligibilityCheckDetail: ownedEligibilityCheckDetail.unsubscribe,
  }
}

const lexicons = Object.entries(texts)
  .reduce((previous, [key, value]) => {
    Object.entries(value)
      .forEach(([language, value]) => {
        if (!previous[language]) previous[language] = {}
        previous[language].id = language
        previous[language][key] = value
      })
    return previous
  }, {})

function ValueNotifier({ seed, renotify = true }) {
  let value
  (async () => {
    value = await seed
    receivers.forEach(receiver => receiver(value))
  })()
  const receivers = new Set([])

  return {
    get value() {
      return value
    },
    set value(newValue) {
      value = newValue
      receivers.forEach(receiver => receiver(newValue))
    },
    subscribe: (receiver) => {
      renotify && receiver(value)
      receivers.add(receiver)
    },
    unsubscribe: (receiver) => {
      receivers.delete(receiver)
    },
  }
}

export default Coordinator