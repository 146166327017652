import { useEffect, useState } from "react"
import Layout from "./layout";
import { useHandler } from "./handler_provider";
import { Link } from "react-router-dom";
import { Select } from "antd";

function OwnedProjects() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [ownedProjectSummaries, setOwnedProjectSummaries] = useState([])
  const [sortBy, setSortBy] = useState('-id')
  useEffect(() => {
    handler.requestOwnedProjectSummariesLookup({ sortBy })
    handler.subToOwnedProjectSummaries(setOwnedProjectSummaries)
    return () => {
      handler.unsubFromOwnedProjectSummaries(setOwnedProjectSummaries)
    }
  }, [handler, sortBy])
  useEffect(() => {
    const navbar = document.getElementById('navbar')
    const subNavbar = document.getElementById('sub-navbar')
    const observer = new ResizeObserver(([navbar]) => {
      requestAnimationFrame(() => {
        subNavbar.style.top = `${navbar.target.offsetHeight - 0.9}px`
      })
    })
    observer.observe(navbar)
    return () => observer.unobserve(navbar)
  }, [])
  useEffect(() => {
    document.body.classList.add('[&_.ant-select-item-option-content]:font-sans')
    document.body.classList.add('[&_.ant-select-item-option-content]:font-semibold')
    document.body.classList.add('[&_.ant-select-item-option-content]:text-base')
    return () => {
      document.body.classList.remove('[&_.ant-select-item-option-content]:font-sans')
      document.body.classList.remove('[&_.ant-select-item-option-content]:font-semibold')
      document.body.classList.remove('[&_.ant-select-item-option-content]:text-base')
    }
  }, [])

  return <Layout>
    <div
      id="sub-navbar"
      className={[
        'flex',
        'flex-col',
        'gap-2',
        'bg-white',
        'px-14',
        'py-6',
        'sticky',
        'z-10',
      ].join(' ')}
    >
      <div
        className={[
          'flex',
          'flex-col',
          'lg:flex-row',
          'gap-2',
          'justify-between',
        ].join(' ')}
      >
        <div className={[
          'font-semibold',
          'text-2xl',
          'text-black',
          'flex',
          'flex-row',
          'items-center',
        ].join(' ')}>
          <div>
            {ownedProjectSummaries.length ?? 0} {lexicon.carbon_projects_available}
          </div>
        </div>
        <div className={[
          'flex',
          'flex-row',
          'gap-2',
          'bg-white',
          'items-center',
          'text-base',
          (
            !ownedProjectSummaries ||
            !ownedProjectSummaries.length
          ) ? 'hidden' : '',
        ].join(' ')}>
          <div>
            {lexicon.sort_by}:
          </div>
          <div className={[
            '[&_.ant-select-selection-item]:font-sans',
            '[&_.ant-select-selection-item]:font-semibold',
            '[&_.ant-select-selection-item]:text-base',
            '[&_.ant-select-arrow]:text-sm',
          ].join(' ')}>
            <Select
              value={sortBy}
              options={[
                { value: '-id', label: lexicon.latest },
                { value: 'id', label: lexicon.earliest },
              ]}
              onChange={setSortBy}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-3 lg:justify-end">
        <Link to="/project-submission">
          <button className="rounded-md bg-[#3EB6AE] p-3 text-white">
            {lexicon.submit_project}
          </button>
        </Link>
      </div>
    </div>
    <div className="min-h-screen flex flex-col font-sans bg-[#F3F3F9]">
      {
        !ownedProjectSummaries || !ownedProjectSummaries.length
          ? <div className="flex justify-center items-center flex-1 text-3xl">
            🍀
          </div>
          : <div className="px-4 lg:px-14 py-8">
            <div className={[
              'bg-white',
              'rounded-md',
              'flex',
              'flex-col',
              'p-4',
              'gap-3',
              'lg:gap-1',
              'shadow-md',
              'text-sm',
              'border',
              (
                !ownedProjectSummaries ||
                !ownedProjectSummaries.length
              ) ? 'hidden' : '',
            ].join(' ')}>
              {ownedProjectSummaries.map((summary, index) => {
                return <div key={summary.id} className="flex lg:flex-col border rounded-md">
                  <div
                    className={[
                      'bg-[#F3F3F9]',
                      'rounded-t-md',
                      'flex-[0_0_auto]',
                      'flex',
                      'flex-col',
                      'items-start',
                      'lg:items-center',
                      'lg:flex-row',
                      index !== 0 ? 'lg:hidden' : '',
                    ].join(' ')}
                  >
                    <div className="flex-1 p-3 font-semibold">
                      Id
                    </div>
                    <div className="flex-1 p-3 font-semibold">
                      {lexicon.project_name}
                    </div>
                    <div className="flex-1 p-3 font-semibold">
                      {lexicon.submit_date}
                    </div>
                    <div className="flex-1 p-3 font-semibold">
                      Status
                    </div>
                    <div className="flex-1 p-3 font-semibold">
                      {lexicon.action}
                    </div>
                  </div>
                  <div
                    className={[
                      'flex-1',
                      'flex',
                      'flex-col',
                      'items-stretch',
                      'lg:items-center',
                      'lg:flex-row',
                      'truncate',
                    ].join(' ')}
                  >
                    <div className="flex-1 p-3">
                      {summary.id}
                    </div>
                    <div className="flex-1 p-3 truncate">
                      {summary.name}
                    </div>
                    <div className="flex-1 p-3">
                      {(() => {
                        const time = new Date(Date.parse(summary.createdAt))
                        const date = time.getDate()
                        const month = time.getMonth()
                        const year = time.getFullYear()
                        return `${year}-${month}-${date}`
                      })()}
                    </div>
                    <div className="flex-1 p-3 flex flex-row items-center">
                      <div className={[
                        // 'border-2',
                        // 'border-[#4C638C]',
                        // 'rounded-full',
                        // 'p-1',
                        // 'bg-[#F3F3F9]',
                      ].join(' ')}>
                        Pre-Feasibility
                      </div>
                    </div>
                    <Link to={`/owned-projects/${summary.id}`} className="flex-1 p-3">
                      {lexicon.see_details}
                    </Link>
                  </div>
                </div>
              })}
            </div>
          </div>
      }
    </div>
  </Layout>
}

export default OwnedProjects