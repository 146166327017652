import { FieldTimeOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHandler } from "./handler_provider";
import logo from "./images/dark-logo.webp";

export default function ForgotPassword() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [otpRenewalResult, setOtpRenewalResult] = useState(null)
  useEffect(() => {
    const setValidOtpRenewalResult = (result) => {
      result && setOtpRenewalResult(result)
    }
    handler.subToOtpRenewalResult(setValidOtpRenewalResult)
    return () => handler.unsubFromOtpRenewalResult(setValidOtpRenewalResult)
  }, [handler])

  const Content = (page) => {
    switch (page) {
      case 0:
        return (
          <>
            <div
              className="alert alert-borderless alert-warning text-center mb-2 mx-2 text-base"
              role="alert"
            >
              {lexicon.enter_your_email_and_instructions_will_be_sent_to_you}!
            </div>
            <div className="p-2">
              <Form
                layout="vertical"
                name="loginForm"
                className="login-form"
                onFinish={(values) => {
                  handler.requestUserOtpRenewal({ email: values.email })
                }}
              >
                <Form.Item
                  label={lexicon.email}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Email",
                    },
                    {
                      type: "email",
                      message: "Invalid Email",
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <MailOutlined
                        style={{ color: "lightGray" }}
                        className="site-form-item-icon"
                      />
                    }
                    placeholder={lexicon.email}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="bg-[#3EB6AE]"
                    block
                  >
                    {lexicon.send_otp}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div
              className="alert alert-borderless alert-warning text-center text-base mb-2 mx-2"
              role="alert"
            >
              {lexicon.please_enter_your_new_password}
            </div>
            <div className="p-2">
              <Form
                layout="vertical"
                name="loginForm"
                className="login-form"
                onFinish={(values) => {
                  handler.requestUserPasswordRenewal({
                    email: values.email,
                    password: values.password,
                    otp: values.otp,
                  })
                }}
              >
                <Form.Item
                  label={lexicon.email}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                    {
                      type: "email",
                      message: "Wrong Email Format",
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <MailOutlined
                        style={{ color: "lightGray" }}
                        className="site-form-item-icon"
                      />
                    }
                    placeholder={lexicon.email}
                  />
                </Form.Item>
                <Form.Item
                  className="custom-label"
                  label={lexicon.new_password}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={
                      <LockOutlined
                        style={{ color: "lightGray" }}
                        className="site-form-item-icon"
                      />
                    }
                    type="password"
                    placeholder={lexicon.password}
                  />
                </Form.Item>
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Please input your OTP!",
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <FieldTimeOutlined
                        style={{ color: "lightGray" }}
                        className="site-form-item-icon"
                      />
                    }
                    type="text"
                    placeholder="OTP"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button bg-[#3EB6AE]"
                    block
                  >
                    {lexicon.change_password}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        );
      default:
        <></>;
    }
  }

  return <div className="auth-page-wrapper pt-5">
    <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div className="bg-overlay"></div>
      <div className="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1440 120"
        >
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <div className="auth-page-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mt-sm-5 mb-4 text-white-50">
              <Link to="/" className="d-inline-block auth-logo">
                <img src={logo} alt="" className="max-h-5" />
              </Link>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card mt-4">
              <div className="card-body p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary text-base">
                    {lexicon.forgot_password}?
                  </h5>
                  <lord-icon
                    src={"https://cdn.lordicon.com/rhvddzym.json"}
                    trigger="loop"
                    colors="primary:#0ab39c"
                    className="avatar-xl"
                    style={{ height: "100px", width: "100px" }}
                  ></lord-icon>
                </div>

                <Content />
                {Content(!otpRenewalResult ? 0 : 1)}
              </div>
            </div>

            <div className="mt-4 text-center">
              <p className="mb-0">
                {lexicon.take_me_back_to}{" "}
                <Link
                  to="/login"
                  className="fw-semibold text-primary text-decoration-underline"
                >
                  {lexicon.sign_in_page}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <p className="mb-0 text-muted">
                &copy;&nbsp;{new Date().getFullYear()} TruClimate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
}
