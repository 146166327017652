import { Avatar, FloatButton, Select } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { useHandler } from "./handler_provider"
import LightTruClimateLogo from "./images/light-truclimate.svg"
import colors from "./colors"
import { mdiMenu } from "@mdi/js"
import Icon from "@mdi/react"
import ReactCountryFlag from "react-country-flag"
import Footer from "../component/footer"

export default function Layout({ children }) {
  const handler = useHandler()
  const [token, setToken] = useState('waiting')
  useEffect(() => {
    handler.subToToken(setToken)
    return () => handler.unsubFromToken(setToken)
  }, [handler])
  const [userInfo, setUserInfo] = useState('waiting')
  const initials = userInfo === 'waiting' || !userInfo ? '' : [
    userInfo.name[0].toUpperCase(),
  ].join(' ')
  useEffect(() => {
    handler.requestUserInfoLookup()
    handler.subToUserInfo(setUserInfo)
    return () => handler.unsubFromUserInfo(setUserInfo)
  }, [handler])
  const [expand, setExpand] = useState(false)
  const [large, setLarge] = useState(false)
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 992px)")
    setLarge(mediaQuery.matches)
    const setMediaQuery = ({ matches }) => setLarge(matches)
    mediaQuery.addEventListener('change', setMediaQuery)
    return () => mediaQuery.removeEventListener('change', setMediaQuery)
  }, [])
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const languageInput = useRef(null)

  return <div className="flex flex-col bg-[#F3F3F9] text-base">
    <nav
      id="navbar"
      className={[
        'shadow-none',
        'bg-white',
        'sticky',
        'top-0',
        'z-50',
        'font-sans',
        'px-0',
        'bg-slate-300',
      ].join(' ')}
    >
      <div className={[
        'flex',
        'flex-col',
        'lg:flex-row',
        'px-11',
        'md:px-14',
        'lg:min-w-full',
        'bg-white',
        'justify-between',
      ].join(' ')}>
        <div className="flex flex-row justify-between items-center">
          <Link to="/" className="flex lg:flex-row lg:items-center py-4">
            <img className="max-h-6" src={LightTruClimateLogo} height="25" alt="logo dark" />
          </Link>
          <Icon
            path={mdiMenu}
            size={1}
            className="lg:hidden"
            onClick={() => {
              setExpand(!expand)
            }}
          />
        </div>
        <div
          className={[
            'flex',
            'items-center',
            '[overflow:hidden]',
            'lg:overflow-visible',
            !large ? 'transition-all duration-700' : 'transition-none',
            !expand ? 'max-h-0' : 'max-h-[29rem]',
            'lg:max-h-screen',
          ].join(' ')}
        >
          <div
            className={[
              'flex',
              'flex-col',
              'lg:flex-row',
              'justify-start',
              'lg:justify-end',
              'items-start',
              'lg:items-center',
              'gap-3',
              'pb-[1.5rem]',
              'lg:pb-0',
            ].join(' ')}
          >
            <ul
              className={[
                'flex',
                'flex-col',
                'lg:flex-row',
                'lg:items-center',
                'gap-3',
                'lg:gap-3',
                'text-base',
                '[&_.active]:text-[#0AB39C]',
              ].join(' ')}
            >
              <li className="nav-item">
                <NavLink className="nav-link text-base font-medium hover:text-[#0AB39C]" to="/">
                  {lexicon.home}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-base font-medium hover:text-[#0AB39C]" to="/inquiry">
                  {lexicon.request}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-base font-medium hover:text-[#0AB39C]" to="https://truclimate.earth/contact">
                  {lexicon.contact}
                </NavLink>
              </li>
            </ul>
            <div className="relative flex flex-col-reverse lg:flex-row gap-3">
              <div>
                <Select
                  ref={languageInput}
                  value={
                    lexicon !== 'waiting' && lexicon ? lexicon.id : 'en-US'
                  }
                  options={[
                    {
                      value: 'en-US',
                      label: <ReactCountryFlag countryCode="US" svg />,
                    },
                    {
                      value: 'id-ID',
                      label: <ReactCountryFlag countryCode="ID" svg />,
                    },
                  ]}
                  onChange={handler.requestLanguageChange}
                />
              </div>
              <div
                className={[
                  'peer',
                  'hidden',
                  'lg:flex',
                  'lg:flex-row',
                  'lg:gap-3',
                  'lg:items-center',
                  'lg:justify-end',
                  token === 'waiting' || !token ? 'lg:hidden' : '',
                ].join(' ')}
                onMouseOver={() => languageInput.current.blur()}
              >
                <Avatar className="z-10 bg-[#3EB6AE] align-middle cursor-default">
                  <span className="uppercase">
                    {initials}
                  </span>
                </Avatar>
              </div>
              <div className={[
                'lg:absolute',
                'lg:hidden',
                'lg:peer-hover:flex',
                'lg:hover:flex',
                '-top-[0.2rem]',
                '-right-[0.2rem]',
                'flex',
                'flex-col',
                'items-start',
                'gap-3',
                'font-medium',
                'bg-white',
                'w-max',
                'lg:pl-5',
                'lg:pr-12',
                'lg:pt-2',
                'lg:pb-3',
                'lg:rounded-t-2xl',
                'lg:rounded-b-2xl',
                'lg:border-2',
                'lg:border-[#3EB6AE]',
                'lg:shadow-md',
                '[&_.active]:text-[#0AB39C]',
                'z-[9]',
              ].join(' ')}>
                <NavLink
                  to="/owned-bookings"
                  className="hover:text-[#0AB39C] text-base"
                >
                  {lexicon.my_bookings}
                </NavLink>
                <NavLink
                  to="/owned-inquiries"
                  className="hover:text-[#0AB39C] text-base"
                >
                  {lexicon.my_requests}
                </NavLink>
                {/* <NavLink
                  to="/owned-projects"
                  className="hover:text-[#0AB39C] text-base"
                >
                  {lexicon.my_projects}
                </NavLink> */}
                <NavLink
                  to="/eligibility-check-submission/owned"
                  className="hover:text-[#0AB39C] text-base"
                >
                  {lexicon.my_eligibility_checks}
                </NavLink>
                <div className="hover:text-[#0AB39C] text-base">
                  <button onClick={() => handler.requestUserTokenDisposal()}>
                    {lexicon.sign_out}
                  </button>
                </div>
              </div>
            </div>
            <div className={[
              'flex-1',
              'flex',
              'flex-col',
              'lg:flex-row',
              'lg:gap-2',
              'lg:justify-end',
              'items-baseline',
              'text-base',
              'font-medium',
              'text-[#212529]',
              token === 'waiting' || !token ? '' : 'hidden',
            ].join(' ')}>
              <Link
                to={[
                  process.env.REACT_APP_ACCOUNT_URL,
                  '/login?',
                  `redirect_=${window.location.href}`
                ].join('')}
                className={[
                  'lg:border-[#4C638C]',
                  'lg:text-[#4C638C]',
                  'lg:hover:text-[#4C638C]',
                  'lg:rounded-md',
                  'lg:border',
                  'lg:px-3',
                  'py-2',
                ].join(' ')}
              >
                {lexicon.sign_in}
              </Link>
              <Link
                to={[
                  process.env.REACT_APP_ACCOUNT_URL,
                  '/register?',
                  `redirect_=${window.location.href}`
                ].join('')}
                className={[
                  'decoration-transparent',
                  'lg:bg-[#3EB6AE]',
                  'lg:border-[#3EB6AE]',
                  'lg:text-white',
                  'lg:hover:text-white',
                  'lg:border',
                  'lg:rounded-md',
                  'lg:px-3',
                  'py-2',
                ].join(' ')}
              >
                {lexicon.sign_up}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    {children}
    <Footer />
    <FloatButton.BackTop
      type="secondary"
      className="[&_.ant-float-btn-content]:text-white hidden lg:block"
      style={{
        background: colors.TRU_BLUE_PRIMARY,
        color: "white",
      }}
    />
  </div >
}


