import { InfoCircleOutlined } from "@ant-design/icons"
import { Listbox } from '@headlessui/react'
import { useCallback, useEffect, useRef, useState } from "react"
import { BsPlusCircle } from "react-icons/bs"
import { RiDownloadLine, RiDraftLine } from 'react-icons/ri'
import { HiCheck, HiChevronUpDown, } from "react-icons/hi2"
import { FaAsterisk } from "react-icons/fa6"
import logo from "./alternative-truclimate.svg"
import { useHandler } from "./handler_provider"

export default function EligibilityCheckSubmission() {
  const handler = useHandler()
  const [language, setLanguage] = useState('en-US')
  useEffect(() => {
    handler.subToLanguage(setLanguage)
    return () => handler.unsubFromLanguage(setLanguage)
  }, [handler])

  useEffect(() => {
    document.getElementById('bootstrap').disabled = true
    return () => document.getElementById('bootstrap').disabled = false
  }, [])

  const { animation, clearAnimation } = useMaskLeftAnimation()
  const [document_, setDocument_] = useState([
    {
      type: 'text',
      required: true,
      key: 'companyName',
      labels: {
        'en-US': 'Company Name',
        'id-ID': 'Nama Perusahaan',
      },
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      key: 'companyAddress',
      labels: {
        'en-US': 'Company Address',
        'id-ID': 'Alamat Perusahaan',
      },
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      key: 'contactPersonName',
      labels: {
        'en-US': 'Contact Person Name',
        'id-ID': 'Nama Narahubung',
      },
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      key: 'contactPersonAddress',
      labels: {
        'en-US': 'Contact Person Address',
        'id-ID': 'Alamat Narahubung',
      },
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      key: 'contactPersonEmail',
      labels: {
        'en-US': 'Contact Person Email',
        'id-ID': 'Surel Narahubung',
      },
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      key: 'projectName',
      labels: {
        'en-US': 'Project Name',
        'id-ID': 'Nama Proyek',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'land_owner',
          labels: {
            'en-US': 'Landowner',
            'id-ID': 'Pemilik Tanah',
          },
        },
        {
          id: 'project_developer',
          labels: {
            'en-US': 'Project Developer',
            'id-ID': 'Pengembang Proyek',
          },
        },
        {
          id: 'jv',
          labels: {
            'en-US': 'JV',
            'id-ID': 'JV',
          },
        },
        {
          id: 'other',
          labels: {
            'en-US': 'Other, Describe Below',
            'id-ID': 'Lainnya, Jelaskan Dibawah',
          },
        },
      ],
      key: 'role',
      labels: {
        'en-US': 'Role',
        'id-ID': 'Peran',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'role': 'other' },
      ],
      key: 'roleDescription',
      labels: {
        'en-US': 'Role Description',
        'id-ID': 'Penjelasan Peran',
      },
      value: undefined,
    },
    {
      type: 'select',
      options: [
        {
          id: 'yes',
          labels: {
            'en-US': 'Yes',
            'id-ID': 'Iya',
          },
        },
        {
          id: 'no',
          labels: {
            'en-US': 'No',
            'id-ID': 'Tidak',
          },
        },
      ],
      key: 'financingRequirement',
      labels: {
        'en-US': 'Financing / Capital Requirement',
        'id-ID': 'Kebutuhan Pembiayaan / Modal',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'production_forest',
          labels: {
            'en-US': 'Production Forest (HP)',
            'id-ID': 'Hutan Produksi (HP)',
          },
          score: 5,
        },
        {
          id: 'production_forest_for_conversion',
          labels: {
            'en-US': 'Production Forest for Conversion Purpose (HPK)',
            'id-ID': 'Hutan Produksi Konversi (HPK)',
          },
          score: 5,
        },
        {
          id: 'protection_forest',
          labels: {
            'en-US': 'Protection Forest (HL)',
            'id-ID': 'Hutan Lindung (HL)',
          },
          score: 3,
        },
        {
          id: 'village_forest',
          labels: {
            'en-US': 'Village Forest (HD)',
            'id-ID': 'Hutan Desa (HD)',
          },
          score: 4,
        },
        {
          id: 'other',
          labels: {
            'en-US': 'Other, Describe Below',
            'id-ID': 'Lainnya, Jelaskan Dibawah',
          },
          score: 1,
        },
      ],
      key: 'landStatus',
      labels: {
        'en-US': 'Land Status',
        'id-ID': 'Status Lahan',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'landStatus': 'other' },
      ],
      key: 'landStatusDescription',
      labels: {
        'en-US': 'Land Status Description',
        'id-ID': 'Penjelasan Status Lahan',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'mixed_crops',
          labels: {
            'en-US': 'Mixed Crops',
            'id-ID': 'Tanaman Campuran',
          },
          score: 3,
        },
        {
          id: 'mangrove',
          labels: {
            'en-US': 'Mangrove',
            'id-ID': 'Bakau',
          },
          score: 5,
        },
        {
          id: 'primary_forest',
          labels: {
            'en-US': 'Primary Forest',
            'id-ID': 'Hutan Primer',
          },
          score: 5,
        },
        {
          id: 'secondary_forest',
          labels: {
            'en-US': 'Secondary Forest',
            'id-ID': 'Hutan Sekunder',
          },
          score: 5,
        },
        {
          id: 'open_land',
          labels: {
            'en-US': 'Open Land',
            'id-ID': 'Lahan Terbuka',
          },
          score: 1,
        },
        {
          id: 'other',
          labels: {
            'en-US': 'Other, Describe Below',
            'id-ID': 'Lainnya, Jelaskan Dibawah',
          },
          score: 1,
        },
      ],
      key: 'ecosystemType',
      labels: {
        'en-US': 'Ecosystem Type',
        'id-ID': 'Tipe Ekosistem',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'ecosystemType': 'other' },
      ],
      key: 'ecosystemTypeDescription',
      labels: {
        'en-US': 'Ecosystem Type Description',
        'id-ID': 'Penjelasan Tipe Ekosistem',
      },
      value: undefined,
    },
    {
      type: 'file',
      required: true,
      accept: '.zip',
      key: 'projectAreaBoundariesAttachment',
      labels: {
        'en-US': 'Project Area Boundaries Attachment',
        'id-ID': 'Lampiran Peta Batas Areal Proyek',
      },
      tooltip: 'shapefiles of the project area boundaries',
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'mineral',
          labels: {
            'en-US': 'Mineral',
            'id-ID': 'Mineral',
          },
          score: 4,
        },
        {
          id: 'peat',
          labels: {
            'en-US': 'Peat',
            'id-ID': 'Gambut',
          },
          score: 5,
        },
        {
          id: 'coastal',
          labels: {
            'en-US': 'Coastal',
            'id-ID': 'Pesisir',
          },
          score: 5,
        },
        {
          id: 'other',
          labels: {
            'en-US': 'Other, Describe Below',
            'id-ID': 'Lainnya, Jelaskan Dibawah',
          },
          score: 1,
        },
      ],
      key: 'soilType',
      labels: {
        'en-US': 'Soil Type',
        'id-ID': 'Tipe Tanah',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'soilType': 'other' },
      ],
      key: 'soilTypeDescription',
      labels: {
        'en-US': 'Soil Type Description',
        'id-ID': 'Penjelasan Tipe Tanah',
      },
      value: undefined,
    },
    {
      type: 'number',
      required: true,
      min: 0,
      unit: 'Ha',
      key: 'areaSize',
      labels: {
        'en-US': 'Area Size',
        'id-ID': 'Luas Wilayah',
      },
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      key: 'province',
      labels: {
        'en-US': 'Province',
        'id-ID': 'Provinsi',
      },
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      key: 'regency',
      labels: {
        'en-US': 'Regency',
        'id-ID': 'Kabupaten',
      },
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      key: 'village',
      labels: {
        'en-US': 'Village',
        'id-ID': 'Desa',
      },
      value: undefined,
    },
    {
      type: 'number',
      required: true,
      min: -90,
      max: 90,
      unit: 'DD',
      key: 'latitude',
      labels: {
        'en-US': 'Latitude of Center Point',
        'id-ID': 'Garis Lintang dari Titik Pusat',
      },
      value: undefined,
    },
    {
      type: 'number',
      required: true,
      min: -180,
      max: 180,
      unit: 'DD',
      key: 'longitude',
      labels: {
        'en-US': 'Longitude of Center Point',
        'id-ID': 'Garis Bujur dari Titik Pusat',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'scattered_areas',
          labels: {
            'en-US': 'Scattered Areas',
            'id-ID': 'Area Tersebar',
          },
          score: 1,
        },
        {
          id: 'one_whole_area',
          labels: {
            'en-US': 'One Whole Area',
            'id-ID': 'Area Utuh',
          },
          score: 4,
        },
      ],
      key: 'areaDistribution',
      labels: {
        'en-US': 'Area Distribution',
        'id-ID': 'Distribusi Wilayah',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'road',
          labels: {
            'en-US': 'Road',
            'id-ID': 'Jalan',
          },
          score: 1,
        },
        {
          id: 'river',
          labels: {
            'en-US': 'River',
            'id-ID': 'Sungai',
          },
          score: 5,
        },
        {
          id: 'forest_path',
          labels: {
            'en-US': 'Forest Path',
            'id-ID': 'Jalur Hutan',
          },
          score: 3,
        },
        {
          id: 'other',
          labels: {
            'en-US': 'Other, Describe Below',
            'id-ID': 'Lainnya, Jelaskan Dibawah',
          },
          score: 1,
        },
      ],
      key: 'dominantAccess',
      labels: {
        'en-US': 'Dominant Access',
        'id-ID': 'Akses Dominan',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'dominantAccess': 'other' },
      ],
      key: 'dominantAccessDescription',
      labels: {
        'en-US': 'Dominant Access Description',
        'id-ID': 'Penjelasan Akses Dominan',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'shm',
          labels: {
            'en-US': 'Sertifikat Hak Milik',
            'id-ID': 'Sertifikat Hak Milik',
          },
          score: 5,
        },
        {
          id: 'apl',
          labels: {
            'en-US': 'Area Penggunaan Lain',
            'id-ID': 'Area Penggunaan Lain',
          },
          score: 2,
        },
        {
          id: 'iphps',
          labels: {
            'en-US': 'Izin Pemanfaatan Hutan Perhutanan Sosial',
            'id-ID': 'Izin Pemanfaatan Hutan Perhutanan Sosial',
          },
          score: 5,
        },
        {
          id: 'pbph',
          labels: {
            'en-US': 'Perizinan Berusaha Pemanfaatan Hutan',
            'id-ID': 'Perizinan Berusaha Pemanfaatan Hutan',
          },
          score: 5,
        },
        {
          id: 'other',
          labels: {
            'en-US': 'Other, Describe Below',
            'id-ID': 'Lainnya, Jelaskan Dibawah',
          },
          score: 1,
        },
      ],
      key: 'areaPermitStatus',
      labels: {
        'en-US': 'Area Permit Status',
        'id-ID': 'Status Izin Kawasan',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'areaPermitStatus': 'other' },
      ],
      key: 'areaPermitStatusDescription',
      labels: {
        'en-US': 'Area Permit Status Description',
        'id-ID': 'Penjelasan Status Izin Kawasan',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'sk',
          labels: {
            'en-US': 'SK & Tata Batas For Jasling',
            'id-ID': 'SK & Tata Batas For Jasling',
          },
          score: 5,
        },
        {
          id: 'acquisition',
          labels: {
            'en-US': 'Acquisition',
            'id-ID': 'Acquisition',
          },
          score: 3,
        },
        {
          id: 'apl',
          labels: {
            'en-US': 'APL',
            'id-ID': 'APL',
          },
          score: 1,
        },
      ],
      key: 'legalityLetterType',
      labels: {
        'en-US': 'Legality Letter Type',
        'id-ID': 'Tipe Surat Legalitas Pengusahaan Tanah',
      },
      value: undefined,
    },
    {
      type: 'file',
      required: true,
      accept: '.pdf, .jpeg, .jpg',
      key: 'legalityLetterAttachment',
      labels: {
        'en-US': 'Legality Letter Attachment',
        'id-ID': 'Lampiran Surat Legalitas Pengusahaan Tanah',
      },
      tooltip: 'surat keputusan/SK copy',
      value: undefined,
    },
    {
      type: 'text',
      key: 'dominantTreeSpeciesDescription',
      labels: {
        'en-US': 'Dominant Tree Species Description',
        'id-ID': 'Penjelasan Spesies Pohon yang Dominan',
      },
      value: undefined,
    },
    {
      type: 'file',
      accept: '.pdf, .jpeg, .jpg, .doc, .docx, .xls, .xlsx',
      key: 'dominantTreeSpeciesAttachment',
      labels: {
        'en-US': 'Dominant Tree Species Attachment',
        'id-ID': 'Lampiran Spesies Pohon yang Dominan',
      },
      value: undefined,
    },
    {
      type: 'text',
      key: 'floraAndFaunaMonitoringDescription',
      labels: {
        'en-US': 'Flora and Fauna Monitoring Description',
        'id-ID': 'Penjelasan Pemantauan Flora dan Fauna',
      },
      value: undefined,
    },
    {
      type: 'file',
      accept: '.pdf, .jpeg, .jpg, .doc, .docx, .xls, .xlsx',
      key: 'floraAndFaunaMonitoringAttachment',
      labels: {
        'en-US': 'Flora and Fauna Monitoring Attachment',
        'id-ID': 'Lampiran Pemantauan Flora dan Fauna',
      },
      value: undefined,
    },
    {
      type: 'number',
      unit: '/ Year',
      min: 0,
      key: 'monitoringFrequency',
      labels: {
        'en-US': 'Monitoring Frequency',
        'id-ID': 'Frekuensi Pemantauan',
      },
      value: undefined,
    },
    {
      type: 'number',
      required: true,
      min: 0,
      scoring: [
        [0, 5],
        [1, 3],
      ],
      key: 'enclaveCommunitiesNumber',
      labels: {
        'en-US': 'Number of Communities Inside The Permit Area',
        'id-ID': 'Jumlah Komunitas Di Dalam Wilayah Perizinan',
      },
      value: undefined,
    },
    {
      type: 'number',
      required: true,
      min: 0,
      scoring: [
        [0, 5],
        [1, 4],
        [2, 3],
        [3, 1],
      ],
      key: 'adjacentCommunitiesNumber',
      labels: {
        'en-US': 'Number of Communities Around The Permit Area',
        'id-ID': 'Jumlah Komunitas Di Sekitar Wilayah Perizinan',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'forest_product_collection',
          labels: {
            'en-US': 'Forest Product Collection, Describe Below',
            'id-ID': 'Hasil Hutan Kayu, Jelaskan Dibawah',
          },
          score: 1,
        },
        {
          id: 'non_forest_product_collection',
          labels: {
            'en-US': 'Non-forest Product Collection, Describe Below',
            'id-ID': 'Hasil Hutan Bukan Kayu, Jelaskan Dibawah',
          },
          score: 3,
        },
        {
          id: 'none',
          labels: {
            'en-US': 'No Dependencies',
            'id-ID': 'Tidak Ada Ketergantungan',
          },
          score: 5,
        },
      ],
      key: 'communitiesDependencies',
      labels: {
        'en-US': 'Communities Dependencies',
        'id-ID': 'Ketergantungan Dari Komunitas',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'communitiesDependencies': 'forest_product_collection' },
        { 'communitiesDependencies': 'non_forest_product_collection' },
      ],
      key: 'communitiesDependenciesDescription',
      labels: {
        'en-US': 'Communities Dependencies Description',
        'id-ID': 'Penjelasan Ketergantungan Dari Komunitas',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'yes',
          labels: {
            'en-US': 'Yes',
            'id-ID': 'Iya',
          },
          score: 5,
        },
        {
          id: 'no',
          labels: {
            'en-US': 'No',
            'id-ID': 'Tidak',
          },
          score: 3,
        },
      ],
      key: 'communitiesStatisticalDataAccess',
      labels: {
        'en-US': 'Access To Statistical Data of The Communities',
        'id-ID': 'Akses Ke Data Statistik Dari Komunitas',
      },
      value: undefined,
    },
    {
      type: 'select',
      options: [
        {
          id: 'yes',
          labels: {
            'en-US': 'Yes',
            'id-ID': 'Iya',
          },
        },
        {
          id: 'no',
          labels: {
            'en-US': 'No',
            'id-ID': 'Tidak',
          },
        },
      ],
      key: 'sdgsAwareness',
      labels: {
        'en-US': 'Awareness of United Nations SDGs',
        'id-ID': 'Pengetahuan tentang TPB Perserikatan Bangsa-Bangsa',
      },
      value: undefined,
    },
    {
      type: 'multiselect',
      options: [
        {
          id: 1,
          labels: {
            'en-US': '1. No Poverty',
            'id-ID': '1. No Poverty',
          },
        },
        {
          id: 2,
          labels: {
            'en-US': '2. Zero Hunger',
            'id-ID': '2. Zero Hunger',
          },
        },
        {
          id: 3,
          labels: {
            'en-US': '3. Good Health and Well-Being',
            'id-ID': '3. Good Health and Well-Being',
          },
        },
        {
          id: 4,
          labels: {
            'en-US': '4. Quality Education',
            'id-ID': '4. Quality Education',
          },
        },
        {
          id: 5,
          labels: {
            'en-US': '5. Gender Equality',
            'id-ID': '5. Gender Equality',
          },
        },
        {
          id: 6,
          labels: {
            'en-US': '6. Clean Water and Sanitation',
            'id-ID': '6. Clean Water and Sanitation',
          },
        },
        {
          id: 7,
          labels: {
            'en-US': '7. Affordable and Clean Energy',
            'id-ID': '7. Affordable and Clean Energy',
          },
        },
        {
          id: 8,
          labels: {
            'en-US': '8. Decent Work and Economic Growth',
            'id-ID': '8. Decent Work and Economic Growth',
          },
        },
        {
          id: 9,
          labels: {
            'en-US': '9. Industry, Innovation and Infrastructure',
            'id-ID': '9. Industry, Innovation and Infrastructure',
          },
        },
        {
          id: 10,
          labels: {
            'en-US': '10. Reduced Inqualities',
            'id-ID': '10. Reduced Inqualities',
          },
        },
        {
          id: 11,
          labels: {
            'en-US': '11. Sustainable Cities and Communities',
            'id-ID': '11. Sustainable Cities and Communities',
          },
        },
        {
          id: 12,
          labels: {
            'en-US': '12. Responsible Consumption and Production',
            'id-ID': '12. Responsible Consumption and Production',
          },
        },
        {
          id: 13,
          labels: {
            'en-US': '13. Climate Action',
            'id-ID': '13. Climate Action',
          },
        },
        {
          id: 14,
          labels: {
            'en-US': '14. Life Below Water',
            'id-ID': '14. Life Below Water',
          },
        },
        {
          id: 15,
          labels: {
            'en-US': '15. Life On Land',
            'id-ID': '15. Life On Land',
          },
        },
        {
          id: 16,
          labels: {
            'en-US': '16. Peace, Justice and Strong Institutions',
            'id-ID': '16. Peace, Justice and Strong Institutions',
          },
        },
        {
          id: 17,
          labels: {
            'en-US': '17. Partnership For The Goals',
            'id-ID': '17. Partnership For The Goals',
          },
        },
      ],
      key: 'contributedSdgs',
      labels: {
        'en-US': 'SDGs your company has contributed to',
        'id-ID': 'TPB yang pernah dikontribusikan perusahaan',
      },
      value: undefined,
    },
    {
      type: 'multiselect',
      options: [
        {
          id: 1,
          labels: {
            'en-US': '1. No Poverty',
            'id-ID': '1. No Poverty',
          },
        },
        {
          id: 2,
          labels: {
            'en-US': '2. Zero Hunger',
            'id-ID': '2. Zero Hunger',
          },
        },
        {
          id: 3,
          labels: {
            'en-US': '3. Good Health and Well-Being',
            'id-ID': '3. Good Health and Well-Being',
          },
        },
        {
          id: 4,
          labels: {
            'en-US': '4. Quality Education',
            'id-ID': '4. Quality Education',
          },
        },
        {
          id: 5,
          labels: {
            'en-US': '5. Gender Equality',
            'id-ID': '5. Gender Equality',
          },
        },
        {
          id: 6,
          labels: {
            'en-US': '6. Clean Water and Sanitation',
            'id-ID': '6. Clean Water and Sanitation',
          },
        },
        {
          id: 7,
          labels: {
            'en-US': '7. Affordable and Clean Energy',
            'id-ID': '7. Affordable and Clean Energy',
          },
        },
        {
          id: 8,
          labels: {
            'en-US': '8. Decent Work and Economic Growth',
            'id-ID': '8. Decent Work and Economic Growth',
          },
        },
        {
          id: 9,
          labels: {
            'en-US': '9. Industry, Innovation and Infrastructure',
            'id-ID': '9. Industry, Innovation and Infrastructure',
          },
        },
        {
          id: 10,
          labels: {
            'en-US': '10. Reduced Inqualities',
            'id-ID': '10. Reduced Inqualities',
          },
        },
        {
          id: 11,
          labels: {
            'en-US': '11. Sustainable Cities and Communities',
            'id-ID': '11. Sustainable Cities and Communities',
          },
        },
        {
          id: 12,
          labels: {
            'en-US': '12. Responsible Consumption and Production',
            'id-ID': '12. Responsible Consumption and Production',
          },
        },
        {
          id: 13,
          labels: {
            'en-US': '13. Climate Action',
            'id-ID': '13. Climate Action',
          },
        },
        {
          id: 14,
          labels: {
            'en-US': '14. Life Below Water',
            'id-ID': '14. Life Below Water',
          },
        },
        {
          id: 15,
          labels: {
            'en-US': '15. Life On Land',
            'id-ID': '15. Life On Land',
          },
        },
        {
          id: 16,
          labels: {
            'en-US': '16. Peace, Justice and Strong Institutions',
            'id-ID': '16. Peace, Justice and Strong Institutions',
          },
        },
        {
          id: 17,
          labels: {
            'en-US': '17. Partnership For The Goals',
            'id-ID': '17. Partnership For The Goals',
          },
        },
      ],
      key: 'potentialSdgs',
      labels: {
        'en-US': 'SDGs your company can potentially contribute to',
        'id-ID': 'TPB yang berpotensi dikontribusikan perusahaan',
      },
      value: undefined,
    },
    {
      type: 'select',
      options: [
        {
          id: 'yes',
          labels: {
            'en-US': 'Yes, Describe Below',
            'id-ID': 'Iya, Jelaskan Dibawah',
          },
        },
        {
          id: 'no',
          labels: {
            'en-US': 'No',
            'id-ID': 'Tidak',
          },
        },
      ],
      key: 'communitiesEngagement',
      labels: {
        'en-US': 'Previous or Current Engagement with Local Communities',
        'id-ID': 'Pendekatan Sebelumnya atau Saat Ini dengan Komunitas Lokal',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'communitiesEngagement': 'yes' },
      ],
      key: 'communitiesEngagementDescription',
      labels: {
        'en-US': 'Communities Engagement Description',
        'id-ID': 'Penjelasan Pendekatan dengan Komunitas Lokal',
      },
      value: undefined,
    },
    {
      type: 'select',
      options: [
        {
          id: 'yes_unsolved',
          labels: {
            'en-US': 'Yes (Unsolved), Describe Below',
            'id-ID': 'Iya (Belum Terselesaikan), Jelaskan Dibawah',
          },
          score: 1,
        },
        {
          id: 'yes_solved',
          labels: {
            'en-US': 'Yes (Solved), Describe Below',
            'id-ID': 'Iya (Terselesaikan), Jelaskan Dibawah',
          },
          score: 3,
        },
        {
          id: 'no',
          labels: {
            'en-US': 'No',
            'id-ID': 'Tidak',
          },
          score: 5,
        },
      ],
      key: 'communitiesDispute',
      labels: {
        'en-US': 'Previous or Current Dispute with Local Communities',
        'id-ID': 'Sengketa Sebelumnya atau Saat Ini dengan Komunitas Lokal',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'communitiesDispute': 'yes_unsolved' },
        { 'communitiesDispute': 'yes_solved' },
      ],
      key: 'communitiesDisputeDescription',
      labels: {
        'en-US': 'Communities Dispute Description',
        'id-ID': 'Penjelasan Sengketa dengan Komunitas Lokal',
      },
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      options: [
        {
          id: 'yes',
          labels: {
            'en-US': 'Yes, Describe Below',
            'id-ID': 'Iya, Jelaskan Dibawah',
          },
          score: 3,
        },
        {
          id: 'no',
          labels: {
            'en-US': 'No',
            'id-ID': 'Tidak',
          },
          score: 3,
        },
      ],
      key: 'indigenousCommunities',
      labels: {
        'en-US': 'Indigenous Communities',
        'id-ID': 'Komunitas Adat',
      },
      value: undefined,
    },
    {
      type: 'text',
      visibility: [
        { 'indigenousCommunities': 'yes' },
      ],
      key: 'indigenousCommunitiesDescription',
      labels: {
        'en-US': 'Indigenous Communities Description',
        'id-ID': 'Penjelasan Komunitas Adat',
      },
      value: undefined,
    },
    {
      type: 'text',
      key: 'upcomingPlan',
      labels: {
        'en-US': 'Upcoming Plan',
        'id-ID': 'Rencana Mendatang',
      },
      tooltip: 'e.g. to create conservation area, target x number of trees, etc',
      value: undefined,
    },
  ])
  const fillDocument = (key, value) => {
    setDocument_(document_.map(field => {
      if (field.key !== key) {
        return field
      } else if (field.type === 'select' && value && value.score) {
        return {
          ...field,
          value,
          score: value.score,
        }
      } else if (field.type === 'number' && field.scoring && value) {
        return {
          ...field,
          value,
          score: field.scoring.reverse().find(([operand]) => {
            return value >= operand
          })[1],
        }
      } else {
        return {
          ...field,
          value,
        }
      }
    }))
  }
  const [failureAssociation, setFailureAssociation] = useState()
  useEffect(() => {
    handler.subToFailureAssociation(setFailureAssociation)
    return () => handler.unsubFromFailureAssociation(setFailureAssociation)
  }, [handler])
  useEffect(() => {
    if (failureAssociation) {
      setFailureAssociation()
    }
  }, [failureAssociation])
  const fieldDecoder = field => {
    if (field.visibility && field.visibility.length) {
      const visibility = field.visibility.map(condition => {
        return Object.entries(condition).map(([key, value]) => {
          const field = document_.find(field => field.key === key)
          return field?.value?.id
            ? field?.value?.id === value
            : field?.value === value
        }).reduce((a, b) => {
          return a && b
        })
      }).reduce((a, b) => {
        return a || b
      })

      return <HiddenInput key={field.key} visibility={visibility}>
        {(() => {
          if (field.type === 'text') {
            return <TextInput
              key={field.key}
              id={field.key}
              failed={field.key === failureAssociation}
              required={field.required}
              label={field?.labels?.[language]}
              tooltip={field.tooltip}
              value={field.value}
              onChange={value => fillDocument(field.key, value)}
            />
          } else if (field.type === 'number') {
            return <NumberInput
              key={field.key}
              id={field.key}
              failed={field.key === failureAssociation}
              required={field.required}
              label={field?.labels?.[language]}
              tooltip={field.tooltip}
              unit={field.unit}
              min={field.min}
              max={field.max}
              value={field.value}
              onChange={value => fillDocument(field.key, value)}
            />
          } else if (field.type === 'select') {
            return <SelectInput
              key={field.key}
              id={field.key}
              failed={field.key === failureAssociation}
              required={field.required}
              label={field?.labels?.[language]}
              options={field.options}
              value={field.value}
              onChange={value => fillDocument(field.key, value)}
            />
          } else if (field.type === 'multiselect') {
            return <MultiselectInput
              key={field.key}
              id={field.key}
              failed={field.key === failureAssociation}
              required={field.required}
              label={field?.labels?.[language]}
              options={field.options}
              value={field.value}
              onChange={value => fillDocument(field.key, value)}
            />
          } else if (field.type === 'file') {
            return <FileInput
              accept={field.accept}
              key={field.key}
              id={field.key}
              failed={field.key === failureAssociation}
              required={field.required}
              label={field?.labels?.[language]}
              tooltip={field.tooltip}
              value={field.value}
              onChange={value => fillDocument(field.key, value)}
            />
          } else {
            return <></>
          }
        })()}
      </HiddenInput>
    } else {
      return (() => {
        if (field.type === 'text') {
          return <TextInput
            key={field.key}
            id={field.key}
            failed={field.key === failureAssociation}
            required={field.required}
            label={field?.labels?.[language]}
            tooltip={field.tooltip}
            value={field.value}
            onChange={value => fillDocument(field.key, value)}
          />
        } else if (field.type === 'number') {
          return <NumberInput
            key={field.key}
            id={field.key}
            failed={field.key === failureAssociation}
            required={field.required}
            label={field?.labels?.[language]}
            tooltip={field.tooltip}
            unit={field.unit}
            min={field.min}
            max={field.max}
            value={field.value}
            onChange={value => fillDocument(field.key, value)}
          />
        } else if (field.type === 'select') {
          return <SelectInput
            key={field.key}
            id={field.key}
            failed={field.key === failureAssociation}
            required={field.required}
            label={field?.labels?.[language]}
            options={field.options}
            value={field.value}
            onChange={value => fillDocument(field.key, value)}
          />
        } else if (field.type === 'multiselect') {
          return <MultiselectInput
            key={field.key}
            id={field.key}
            failed={field.key === failureAssociation}
            required={field.required}
            label={field?.labels?.[language]}
            options={field.options}
            value={field.value}
            onChange={value => fillDocument(field.key, value)}
          />
        } else if (field.type === 'file') {
          return <FileInput
            accept={field.accept}
            id={field.key}
            key={field.key}
            failed={field.key === failureAssociation}
            required={field.required}
            label={field?.labels?.[language]}
            tooltip={field.tooltip}
            value={field.value}
            onChange={value => fillDocument(field.key, value)}
          />
        } else {
          return <></>
        }
      })()
    }
  }

  return <div
    className={[
      'min-h-screen',
      'bg-white',
      'flex',
      'flex-col',
      'items-stretch',
      'border',
      'border-neutral',
    ].join(' ')}
    onAnimationEnd={clearAnimation}
  >
    <div className={[
      'bg-neutral',
      'h-14',
      'flex',
      'items-center',
      'px-5',
      'md:px-9',
      'lg:px-14',
      'py-7',
      animation,
    ].join(' ')}>
      <img alt="" src={logo} className={[
        'max-h-6',
        animation,
        '[animation-delay:600ms_!important]',
      ].join(' ')} />
    </div>
    <div className={[
      'flex-1',
      'flex',
      'flex-col',
      'items-stretch',
      animation,
    ].join(' ')}>
      <div
        className={[
          'flex',
          'text-3xl',
          'font-bold',
        ].join(' ')}
      >
        <span className={[
          'py-4',
          'px-5',
          'md:px-9',
          'lg:px-14',
          animation,
          '[animation-delay:300ms_!important]',
        ].join(' ')}>
          {{
            'en-US': 'Eligibility Check',
            'id-ID': 'Pemeriksaan Kelayakan',
          }[language]}
        </span>
        <div className="border-l border-neutral"></div>
      </div>
      <div className='flex flex-col items-stretch'>
        <div className={[
          'flex',
          'border-y',
          'border-neutral',
          animation,
        ].join(' ')}>
          <div className={[
            'flex',
            'text-2xl',
            'font-semibold',
            'py-2',
            'pl-5',
            'md:pl-9',
            'lg:pl-14',
            'pr-3',
          ].join(' ')}>
            <span className={[
              animation,
            ].join(' ')}>
              {{
                'en-US': 'Agriculture, Forestry, and Other Land Use',
                'id-ID': 'Pertanian, Kehutanan, dan Penggunaan Lahan Lainnya',
              }[language]}
            </span>
          </div>
          <span className={[
            'flex',
            'justify-center',
            'items-center',
            'text-3xl',
            'leading-[0.9_!important]',
            'border-x',
            'border-neutral',
            'px-2',
          ].join(' ')}>
            <span
              className={[
              ].join(' ')}
            >
              🪵
            </span>
          </span>
        </div>
        <div className={[
          'flex',
          'flex-col',
          'font-semibold',
          'gap-3',
          'border-neutral',
          'px-5',
          'md:px-9',
          'lg:px-14',
          'py-3',
        ].join(' ')}>
          <div className="flex gap-3 flex-col md:flex-row">
            <div className="flex-1 flex flex-col items-stretch gap-3">
              {document_.filter(field => {
                return field.key.startsWith('company')
              }).map(fieldDecoder)}
            </div>
            <div className="flex-1 flex flex-col gap-3">
              {document_.filter(field => {
                return field.key.startsWith('contactPerson')
              }).map(fieldDecoder)}
            </div>
          </div>
        </div>
        <div className={[
          'flex',
          'flex-col',
          'font-semibold',
          'gap-3',
          'border-y',
          'border-neutral',
          'px-5',
          'md:px-9',
          'lg:px-14',
          'py-3',
        ].join(' ')}>
          <div className={[
            'flex-1',
            'flex',
            'flex-col',
            'gap-3',
          ].join(' ')}>
            {document_.filter(field => {
              return !field.key.startsWith('company') &&
                !field.key.startsWith('contactPerson')
            }).map(fieldDecoder)}
          </div>
        </div>
      </div>
    </div>
    <div className={[
      'bg-neutral',
      'h-14',
      animation,
    ].join(' ')}>
    </div>
    <div className={[
      'fixed',
      'bottom-9',
      'right-9',
      'flex',
      'flex-col',
      'justify-center',
      'items-stretch',
      'gap-3',
    ].join(' ')}>
      <button
        className={[
          'flex',
          'justify-center',
          'items-center',
          'rounded-full',
          'bg-neutral',
          'p-3',
        ].join(' ')}
        onClick={() => {
          handler.requestEligibilityCheckDrafting({
            document: document_,
          })
        }}
      >
        <RiDraftLine className="text-3xl text-neutral-content" />
      </button>
      <button
        className={[
          'flex',
          'justify-center',
          'items-center',
          'rounded-full',
          'bg-neutral',
          'p-3',
        ].join(' ')}
        onClick={() => {
          handler.requestEligibilityCheckSubmission({
            document: document_,
          })
        }}
      >
        <BsPlusCircle className="text-3xl text-neutral-content" />
      </button>
    </div>
  </div>
}

function TextInput({
  className,
  id,
  failed,
  required,
  label,
  tooltip,
  disabled,
  value,
  onChange,
}) {
  const { animation, clearAnimation } = useMaskLeftAnimation()
  value = value ?? ''

  const inputElement = useRef()
  useEffect(() => {
    if (failed) {
      inputElement.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      inputElement.current?.focus()
    }
  }, [failed])

  return <div
    className={[
      ...(className?.split(' ') ?? []),
      'flex',
      animation,
    ].join(' ')}
    onAnimationEnd={clearAnimation}
  >
    <div className={[
      'flex-1',
      'flex',
      'items-stretch',
      'relative',
    ].join(' ')}>
      <input
        ref={inputElement}
        id={id}
        type='text'
        placeholder=' '
        className={[
          'peer',
          'input',
          'min-h-full',
          'w-full',
          'border',
          'border-neutral',
          'rounded-none',
          'flex-1',
        ].join(' ')}
        value={value}
        onChange={(event) => {
          if (!event.target.value) {
            onChange(undefined)
          } else {
            onChange(event.target.value)
          }
        }}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className={[
          'absolute',
          'right-0',
          'peer-placeholder-shown:left-0',
          'top-0',
          'px-1',
          'peer-placeholder-shown:px-4',
          'peer-placeholder-shown:flex',
          'peer-placeholder-shown:items-center',
          'font-semibold',
          'peer-placeholder-shown:h-full',
          'peer-placeholder-shown:w-full',
          'text-xs',
          'peer-placeholder-shown:text-base',
          'border-b',
          'border-x',
          'border-neutral',
          'peer-placeholder-shown:border-none',
          'm-0',
          'flex',
          'items-center',
        ].join(' ')}
      >
        <span
          className={[
            animation,
            '[animation-delay:600ms_!important]',
            'flex',
            'items-center',
            'gap-1',
          ].join(' ')}
        >
          {label} {required && <span
            className="text-error leading-4"
          >
            <FaAsterisk className='text-xs' />
          </span>}
        </span>
      </label>
    </div>
    {tooltip && <div
      className={[
        'flex',
        'justify-center',
        'items-center',
        'px-3',
        'border-r',
        'border-y',
        'border-neutral',
        'tooltip',
        'tooltip-left',
        'before:z-50',
      ].join(' ')}
      data-tip={tooltip}
    >
      <InfoCircleOutlined />
    </div>}
  </div>
}

function SelectInput({
  className,
  id,
  failed,
  required,
  label,
  options,
  disabled,
  value,
  onChange,
}) {
  const handler = useHandler()
  const [language, setLanguage] = useState('en-US')
  useEffect(() => {
    handler.subToLanguage(setLanguage)
    return () => handler.unsubFromLanguage(setLanguage)
  }, [handler])

  const { animation, clearAnimation } = useMaskLeftAnimation()
  value = value ?? ''

  const inputElement = useRef()
  useEffect(() => {
    if (failed) {
      inputElement.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      inputElement.current?.focus()
    }
  }, [failed])

  return <div
    className={[
      ...(className?.split(' ') ?? []),
      'group',
      'flex',
      'flex-col',
      'items-stretch',
      'relative',
      animation,
    ].join(' ')}
    onAnimationEnd={clearAnimation}
  >
    <Listbox
      value={value}
      onChange={(value) => {
        if (!value) {
          onChange(undefined)
        } else {
          onChange(value)
        }
      }}
      disabled={disabled}
    >
      {({ disabled, value }) => {
        return <div className="relative">
          <Listbox.Button
            ref={inputElement}
            className={[
              'border',
              'border-neutral',
              'relative',
              'w-full',
              'cursor-default',
              !disabled ? 'bg-white' : 'bg-base-200',
              'py-3',
              'px-3',
              'text-left',
              'sm:text-sm',
              'flex',
              'items-center',
              'justify-between',
              'focus:outline',
              'focus:outline-2',
              'focus:outline-offset-2',
              'focus:outline-[hsl(var(--bc)/0.2)]',
            ].join(' ')}
          >
            <span className={[
              'block',
              'truncate',
              'text-base',
            ].join(' ')}>
              {value?.labels?.[language]}
            </span>
            {!value && <span>
              <HiChevronUpDown
                className={[
                  'text-xl',
                  'text-neutral',
                ].join(' ')}
              />
            </span>}
          </Listbox.Button>
          <Listbox.Options className={[
            'absolute',
            'mt-1',
            'max-h-60',
            'w-full',
            'overflow-auto',
            'bg-white',
            'py-1',
            'text-base',
            'ring-1',
            'ring-black',
            'ring-opacity-5',
            'focus:outline-none',
            'sm:text-sm',
            'z-[1]',
            'border',
            'border-neutral',
          ].join(' ')}>
            {options.map(option => {
              return (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) => [
                    'relative',
                    'z-10',
                    'cursor-default',
                    'select-none',
                    'py-2',
                    'px-4',
                    'flex',
                    active
                      ? 'bg-amber-100 text-amber-900'
                      : 'text-gray-900',
                  ].join(' ')}
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={[
                          'flex-1',
                          'truncate',
                          'text-base',
                          selected ? 'font-semibold' : 'font-medium',
                        ].join(' ')}
                      >
                        {option.labels[language]}
                      </span>
                      {selected ? (
                        <span className={[
                          'left-0',
                          'flex',
                          'items-center',
                          'pl-3',
                          'text-amber-600',
                        ].join(' ')}>
                          <HiCheck
                            className={[
                              'text-lg',
                              'text-amber-600',
                            ].join(' ')}
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              )
            })}
          </Listbox.Options>
          <label
            htmlFor={id}
            className={[
              'pointer-events-none',
              'absolute',
              'right-0',
              value ? '' : 'left-0',
              'top-0',
              'px-2',
              value ? '' : 'px-4',
              value ? '' : 'flex',
              'items-end',
              value ? '' : 'items-center',
              'font-semibold',
              value ? '' : 'h-full',
              value ? '' : 'w-full',
              value ? 'text-xs' : 'text-base',
              'border-y',
              'border-x',
              'border-neutral',
              value ? '' : 'border-none',
              'm-0',
            ].join(' ')}
          >
            <span
              className={[
                animation,
                '[animation-delay:600ms_!important]',
                'flex',
                'items-center',
                'gap-1',
              ].join(' ')}
            >
              {label} {required && <span
                className="text-error leading-4"
              >
                <FaAsterisk className='text-xs' />
              </span>}
            </span>
          </label>
        </div>
      }}
    </Listbox>
  </div >
}

function MultiselectInput({
  className,
  id,
  failed,
  required,
  label,
  options,
  disabled,
  value,
  onChange,
}) {
  const handler = useHandler()
  const [language, setLanguage] = useState('en-US')
  useEffect(() => {
    handler.subToLanguage(setLanguage)
    return () => handler.unsubFromLanguage(setLanguage)
  }, [handler])

  const { animation, clearAnimation } = useMaskLeftAnimation()
  value = value ?? []

  const inputElement = useRef()
  useEffect(() => {
    if (failed) {
      inputElement.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      inputElement.current?.focus()
    }
  }, [failed])

  return <div
    className={[
      ...(className?.split(' ') ?? []),
      'group',
      'flex',
      'flex-col',
      'items-stretch',
      'relative',
      animation,
    ].join(' ')}
    onAnimationEnd={clearAnimation}
  >
    <Listbox
      value={value}
      onChange={(value) => {
        if (!value || !value.length) {
          onChange(undefined)
        } else {
          onChange(value)
        }
      }}
      disabled={disabled}
      multiple
    >
      {({ disabled }) => {
        return <div>
          <Listbox.Button
            ref={inputElement}
            className={[
              'border',
              'border-neutral',
              'relative',
              'w-full',
              'cursor-default',
              !disabled ? 'bg-white' : 'bg-base-200',
              'py-3',
              'px-3',
              'text-left',
              'sm:text-sm',
              'flex',
              'items-center',
              'justify-between',
              'focus:outline',
              'focus:outline-2',
              'focus:outline-offset-2',
              'focus:outline-[hsl(var(--bc)/0.2)]',
            ].join(' ')}
          >
            <span className={[
              'block',
              'truncate',
              'text-base',
            ].join(' ')}>
              {value.map((selectedOption) => {
                return selectedOption.labels[language]
              }).join(' / ')}
            </span>
            {!value.length && <span>
              <HiChevronUpDown
                className={[
                  'text-xl',
                  'text-neutral',
                ].join(' ')}
              />
            </span>}
          </Listbox.Button>
          <Listbox.Options
            className={[
              'absolute',
              'mt-1',
              'max-h-60',
              'w-full',
              'overflow-auto',
              'bg-white',
              'py-1',
              'text-base',
              'ring-1',
              'ring-black',
              'ring-opacity-5',
              'focus:outline-none',
              'sm:text-sm',
              'z-[1]',
              'border',
              'border-neutral',
            ].join(' ')}
          >
            {options.map(option => {
              return (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) => [
                    'relative',
                    'z-10',
                    'cursor-default',
                    'select-none',
                    'py-2',
                    'px-4',
                    'flex',
                    active
                      ? 'bg-amber-100 text-amber-900'
                      : 'text-gray-900',
                  ].join(' ')}
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={[
                          'flex-1',
                          'truncate',
                          'text-base',
                          selected ? 'font-semibold' : 'font-medium',
                        ].join(' ')}
                      >
                        {option.labels[language]}
                      </span>
                      {selected ? (
                        <span className={[
                          'left-0',
                          'flex',
                          'items-center',
                          'pl-3',
                          'text-amber-600',
                        ].join(' ')}>
                          <HiCheck
                            className={[
                              'text-lg',
                              'text-amber-600',
                            ].join(' ')}
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              )
            })}
          </Listbox.Options>
        </div>
      }}
    </Listbox>
    <label
      htmlFor={id}
      className={[
        'pointer-events-none',
        'absolute',
        'right-0',
        value.length ? '' : 'left-0',
        'top-0',
        'px-2',
        value.length ? '' : 'px-4',
        value.length ? '' : 'flex',
        'items-end',
        value.length ? '' : 'items-center',
        'font-semibold',
        value.length ? '' : 'h-full',
        value.length ? '' : 'w-full',
        value.length ? 'text-xs' : 'text-base',
        'border-y',
        'border-x',
        'border-neutral',
        value.length ? '' : 'border-none',
        'm-0',
      ].join(' ')}
    >
      <span
        className={[
          animation,
          '[animation-delay:600ms_!important]',
          'flex',
          'items-center',
          'gap-1',
        ].join(' ')}
      >
        {label} {required && <span
          className="text-error leading-4"
        >
          <FaAsterisk className='text-xs' />
        </span>}
      </span>
    </label>
  </div >
}

function FileInput({
  accept,
  id,
  failed,
  required,
  label,
  tooltip,
  disabled,
  value,
  onChange,
}) {
  const { animation, clearAnimation } = useMaskLeftAnimation()
  const [encoding, setEncoding] = useState(false)

  const fileElement = useRef()
  const inputElement = useRef()
  useEffect(() => {
    if (failed) {
      inputElement.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      inputElement.current?.focus()
    }
  }, [failed])

  return <div
    className={[
      'flex',
      animation,
    ].join(' ')}
    onAnimationEnd={clearAnimation}
  >
    <div className={[
      'flex-1',
      'flex',
      'relative',
    ].join(' ')}>
      <input
        ref={fileElement}
        type="file"
        accept={accept ?? ''}
        className='hidden'
        onChange={async event => {
          if (event.target.value && !encoding) {
            try {
              setEncoding(true)
              const file = {
                name: event.target.files[0].name,
                data: await base64FileEncoder(event.target.files[0]),
              }
              onChange(file)
            } finally {
              setEncoding(false)
            }
          }
        }}
      />
      <button
        ref={inputElement}
        id={id}
        className={[
          'input',
          'input-bordered',
          'border-neutral',
          'rounded-none',
          'w-full',
          'flex',
          'items-center',
          'focus:outline',
          'focus:outline-2',
          'focus:outline-offset-2',
          'focus:outline-[hsl(var(--bc)/0.2)]',
        ].join(' ')}
        disabled={disabled}
        onClick={() => fileElement.current?.click()}
      >
        {!value ? '' : value.name}
      </button>
      <label
        htmlFor={id}
        className={[
          'absolute',
          'right-0',
          value ? '' : 'left-0',
          'top-0',
          'px-1',
          value ? '' : 'px-4',
          value ? '' : 'flex',
          value ? '' : 'items-center',
          'font-semibold',
          value ? '' : 'h-full',
          value ? '' : 'w-full',
          value ? 'text-xs' : 'text-base',
          'border-b',
          'border-x',
          'border-neutral',
          value ? '' : 'border-none',
          'm-0',
          'flex',
          'items-center',
        ].join(' ')}
      >
        <span
          className={[
            animation,
            '[animation-delay:600ms_!important]',
            'flex',
            'items-center',
            'gap-1',
          ].join(' ')}
        >
          {label} {required && <span
            className="text-error leading-4"
          >
            <FaAsterisk className='text-xs' />
          </span>}
        </span>
      </label>
    </div>
    {value && <a
      download={value.name}
      href={value.data}
      className={[
        'flex',
        'justify-center',
        'items-center',
        'px-3',
        'border-r',
        'border-y',
        'border-neutral',
      ].join(' ')}
    >
      <RiDownloadLine />
    </a>}
    {tooltip && <div
      className={[
        'flex',
        'justify-center',
        'items-center',
        'px-3',
        'border-r',
        'border-y',
        'border-neutral',
        'tooltip',
        'tooltip-left',
      ].join(' ')}
      data-tip={tooltip}
    >
      <InfoCircleOutlined />
    </div>}
  </div>
}

async function base64FileEncoder(file) {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      resolve(reader.result)
    })
    reader.addEventListener('error', (error) => {
      reject(error)
    })
    reader.readAsDataURL(file)
  })
}

function NumberInput({
  id,
  failed,
  required,
  label,
  unit,
  tooltip,
  min,
  max,
  disabled,
  value,
  onChange,
}) {
  const { animation, clearAnimation } = useMaskLeftAnimation()
  value = value ?? ''

  const inputElement = useRef()
  useEffect(() => {
    if (failed) {
      inputElement.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      inputElement.current?.focus()
    }
  })

  return <div
    className={[
      'flex',
      animation,
    ].join(' ')}
    onAnimationEnd={clearAnimation}
  >
    <div
      className={[
        'flex-1',
        'flex',
        'relative',
      ].join(' ')}
    >
      <input
        ref={inputElement}
        id={id}
        type='number'
        min={min}
        max={max}
        placeholder=' '
        className={[
          'peer',
          'input',
          'input-bordered',
          'rounded-none',
          'border-neutral',
          'w-full',
        ].join(' ')}
        disabled={disabled}
        value={value}
        onChange={event => {
          if (!event.target.value) {
            onChange(undefined)
          } else {
            if (
              typeof min === 'number' &&
              Number(event.target.value) < min
            ) {
              event.target.value = min
            } else if (
              typeof max === 'number' &&
              Number(event.target.value) > max
            ) {
              event.target.value = max
            }

            onChange(Number(event.target.value))
          }
        }}
      />
      <label
        htmlFor={id}
        className={[
          'absolute',
          'right-0',
          'peer-placeholder-shown:left-0',
          'top-0',
          'px-1',
          'peer-placeholder-shown:px-4',
          'peer-placeholder-shown:flex',
          'items-end',
          'peer-placeholder-shown:items-center',
          'font-semibold',
          'peer-placeholder-shown:h-full',
          'peer-placeholder-shown:w-full',
          'text-xs',
          'peer-placeholder-shown:text-base',
          'border-b',
          'border-x',
          'border-neutral',
          'peer-placeholder-shown:border-none',
          'm-0',
        ].join(' ')}
      >
        <span
          className={[
            animation,
            '[animation-delay:600ms_!important]',
            'flex',
            'items-center',
            'gap-1',
          ].join(' ')}
        >
          {label} {required && <span
            className="text-error leading-4"
          >
            <FaAsterisk className='text-xs' />
          </span>}
        </span>
      </label>
    </div>
    {unit && <div
      className={[
        'flex',
        'justify-center',
        'items-center',
        'bg-neutral',
        'text-neutral-content',
        'px-3',
        'font-extrabold',
      ].join(' ')}
    >
      {unit}
    </div>}
    {tooltip && <div
      className={[
        'flex',
        'justify-center',
        'items-center',
        'px-3',
        'border-r',
        'border-y',
        'border-neutral',
        'tooltip',
        'tooltip-left',
        'before:z-50',
      ].join(' ')}
      data-tip={tooltip}
    >
      <InfoCircleOutlined />
    </div>}
  </div>
}

function HiddenInput({
  className,
  visibility,
  children,
}) {
  const [rendered, setRendered] = useState(visibility)
  useEffect(() => {
    if (visibility && !rendered) setRendered(true)
  }, [visibility, rendered])

  return (visibility || rendered) && <div
    className={[
      ...(className?.split(' ') ?? []),
      !visibility
        ? '[transition:flex_500ms_cubic-bezier(0.4,0,0.2,1)_500ms,max-height_500ms_cubic-bezier(0.4,0,0.2,1)_500ms,margin-top_500ms_cubic-bezier(0.4,0,0.2,1)_500ms,clip-path_500ms_cubic-bezier(0.4,0,0.2,1)]'
        : '[transition:flex_500ms_cubic-bezier(0.4,0,0.2,1),max-height_500ms_cubic-bezier(0.4,0,0.2,1),margin-top_500ms_cubic-bezier(0.4,0,0.2,1),clip-path_500ms_cubic-bezier(0.4,0,0.2,1)_500ms]',
      !visibility
        ? 'max-h-0 [clip-path:inset(0_100%_0_0)] -mt-3'
        : 'max-h-14 [clip-path:inset(0_0_0_0)] -mt-0',
    ].join(' ')}
    onTransitionEnd={() => {
      if (!visibility && rendered) setTimeout(() => setRendered(false), 300)
    }}
  >
    {children}
  </div>
}

function useMaskLeftAnimation() {
  const [animation, setanimation] = useState([
    '[clip-path:inset(0_100%_0_0)]',
    'animate-mask-left',
  ].join(' '))

  const clearAnimation = useCallback(() => {
    setTimeout(() => setanimation(''), 300)
  }, [])

  return { animation, clearAnimation }
}