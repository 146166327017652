// Import Library
import React from "react";
import { Select, Typography } from "antd";

/**
 * @param {{
 * classContainer: string;
 * classContainerAdditional: string;
 * className: string;
 * classNameAdditional: string;
 * label: string;
 * multiple: boolean;
 * name: FieldNames | undefined;
 * onChange: ((value: any, option: any) => void) | undefined;
 * options: Array;
 * placeholder: string;
 * required: boolean;
 * size: "large" | "middle" | "small";
 * value: string | number | readonly string[] | undefined;
 * }} props
 **/

export default function InputSelect(props) {
  const {
    classContainer = "text-[#4C638C]",
    classContainerAdditional = "",
    className = "[&>*]:bg-transparent [&>*]:border-[#4cdfd5] w-full",
    classNameAdditional = "",
    disabled = false,
    label = "",
    multiple = false,
    name = undefined,
    onChange = undefined,
    options = [],
    placeholder = props?.label,
    required = false,
    size = "large",
    value = undefined,
    ...rest
  } = props;

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      {...rest}
      allowClear={true}
      className={[className, classNameAdditional].join(" ")}
      disabled={disabled}
      filterOption={filterOption}
      fieldNames={name}
      mode={multiple ? "multiple" : ""}
      onChange={(value) => onChange(value, name)}
      options={options}
      optionFilterProp="children"
      placeholder={placeholder}
      size={size}
      showSearch={true}
      value={
        multiple
          ? value?.map((value) => value.value) ?? []
          : typeof value === "object"
          ? value?.value
          : value || null
      }
    />
  );
}
