import { Button, Col, DatePicker, Form, Input, Row, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import moment from "moment"
import { useHandler } from "./handler_provider"
import Layout from "./layout"
const { Option } = Select;

function Inquiry() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [creditTypes, setCreditTypes] = useState([])
  const [sdgs, setSdgs] = useState([])
  const [mechanisms, setMechanisms] = useState([])
  const [sectors, setSectors] = useState([])
  useEffect(() => {
    handler.requestCreditTypesLookup()
    handler.requestSdgsLookup()
    handler.requestMechanismsLookup()
    handler.requestSectorsLookup()
    handler.subToCreditTypes(setCreditTypes)
    handler.subToSdgs(setSdgs)
    handler.subToMechanisms(setMechanisms)
    handler.subToSectors(setSectors)
    return () => {
      handler.unsubFromCreditTypes(setCreditTypes)
      handler.unsubFromSdgs(setSdgs)
      handler.unsubFromMechanisms(setMechanisms)
      handler.unsubFromSectors(setSectors)
    }
  }, [handler])
  const [isCarbonOffset, setIsCarbonOffset] = useState(null)
  const disabledDate = (current) => {
    return current && current > moment();
  }

  return <Layout>
    <div className="layout-wrapper landing min-h-screen">
      <section className="section pb-0 hero-section py-8 min-h-screen" id="hero">
        <div className="bg-overlay bg-overlay-pattern"></div>
        <div className="container">
          <div className="row justify-content-center"></div>
        </div>
        <div className="row mx-5">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="text-center mb-5">
                <h1 className="mb-3 fw-semibold">
                  {lexicon.request_offset}
                </h1>
                <p className="text-muted mb-4 ff-secondary">
                  {lexicon.request_offset_description}
                </p>
              </div>
            </div>
          </div>

          <Form
            layout="vertical"
            onFinish={(values) => {
              const productionTime = values.productionYear.toString()
              const productionYear = moment(productionTime).format("yyyy")
              handler.requestProjectAppealSubmission({
                ...values,
                productionYear: +productionYear,
                mechanisms: values.mechanisms || [],
                sdgs: values.sdgs || [],
                sectors: values.sectors || [],
                quantity: +values.quantity,
              })
            }}
            autoComplete="off"
            initialValues={{}}
          >
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={lexicon.request_type}
                  name="tradeType"
                  initialValue={'buy'}
                  rules={[
                    {
                      required: true,
                      message: "Please choose your Request Type",
                    },
                  ]}
                >
                  <Select
                    placeholder={lexicon.choose_your_request_type}
                    disabled={true}
                    options={[
                      {
                        value: "sell",
                        label: "Sell",
                      },
                      {
                        value: "buy",
                        label: "Buy",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={lexicon.instrument_type}
                  name="creditType"
                  rules={[
                    {
                      required: true,
                      message: "Please choose your Instrument type",
                    },
                  ]}
                >
                  <Select
                    onChange={(value) => {
                      if (value === 0) {
                        setIsCarbonOffset(true);
                      } else if (value === 1) {
                        setIsCarbonOffset(false);
                      }
                    }}
                    placeholder={lexicon.choose_your_instrument_type}
                    notFoundContent={
                      !creditTypes.length ? <Spin size="small" /> : null
                    }
                    loading={!creditTypes.length}
                    showSearch
                    allowClear
                  >
                    {creditTypes.map((creditType, index) => (
                      <Option key={index} value={index} label={creditType}>
                        {creditType}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={lexicon.production_year}
                  name="productionYear"
                >
                  <DatePicker
                    picker="year"
                    disabledDate={disabledDate}
                    placeholder={lexicon.select_year_of_production}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={lexicon.quantity}
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Quantity",
                    },
                  ]}
                >
                  <Input
                    placeholder={lexicon.input_your_offset_quantity}
                    addonAfter={(() => {
                      if (
                        typeof isCarbonOffset === 'boolean' &&
                        !isCarbonOffset
                      ) {
                        return <div>MWh</div>
                      } else if (
                        typeof isCarbonOffset === 'boolean' &&
                        isCarbonOffset
                      ) {
                        return <div>
                          tCO<sub>2</sub>e
                        </div>
                      } else {
                        return <></>
                      }
                    })()}
                    min={1}
                  />
                </Form.Item>
              </Col>

              {isCarbonOffset && (
                <>
                  <Col span={12}>
                    <Form.Item
                      label={lexicon.carbon_offset_category}
                      name="sectors"
                    >
                      <Select
                        mode="multiple"
                        style={{
                          width: "100%",
                        }}
                        placeholder={lexicon.choose_your_preferred_offset_category}
                        optionLabelProp="label"
                        notFoundContent={
                          !sectors.length ? <Spin size="small" /> : null
                        }
                        loading={!sectors.length}
                        showSearch
                        allowClear
                      >
                        {sectors.map((sector, index) => (
                          <Option key={index} value={index} label={sector}>
                            {sector}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={lexicon.carbon_project_category}
                      name="mechanisms"
                    >
                      <Select
                        placeholder={lexicon.choose_your_preferred_project_category}
                        mode="multiple"
                        notFoundContent={
                          !mechanisms.length ? <Spin size="small" /> : null
                        }
                        loading={!mechanisms.length}
                        showSearch
                        allowClear
                      >
                        {mechanisms.map((mechanism, index) => (
                          <Option key={index} value={index} label={mechanism}>
                            {mechanism}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={lexicon.sdgs}
                      name="sdgs"
                    >
                      <Select
                        mode="multiple"
                        placeholder={lexicon.choose_your_preferred_sdgs}
                        notFoundContent={
                          !sdgs.length ? <Spin size="small" /> : null
                        }
                        loading={!sdgs.length}
                        showSearch
                        allowClear
                      >
                        {sdgs.map((sdg, index) => (
                          <Option key={index} value={index} label={sdg}>
                            {sdg}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={24}>
                <Form.Item
                  label={lexicon.additional_description}
                  name="description"
                >
                  <Input.TextArea
                    placeholder={lexicon.provide_any_additional_details_you_would_like_us_to_know}
                  />
                </Form.Item>
              </Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-[#3EB6AE]"
                >
                  {lexicon.submit}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </section>
    </div >
  </Layout >
}

export default Inquiry