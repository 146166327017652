// Import Library
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { RiInformationFill, RiPulseFill, RiShieldFill, RiInformationLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Spin, Typography, Upload } from 'antd';

// Import Custom Library
import { defaultDetailEligibility } from '../../utils/constant';
import { useHandler } from '../handler_provider';

// Import Assets
import Layout from '../layout';
import InputSelect from '../../component/form/input-select';
import InputText from '../../component/form/input-text';
import { base64FileEncoder } from '../../lib/image';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import InputCoordinate from '../../component/form/input-coordinate';

export default function EligibilityCheckSubmission() {

  const [getDetailEligibility, setDetailEligibility] = useState([]);
  const [getStatusEligibility, setStatusEligibility] = useState(null);

  const [getInitialData, setInitialData] = useState(true);

  const [getLanguage, setLanguage] = useState('en-US')
  const [getLexicon, setLexicon] = useState({});

  const [getStep, setStep] = useState(1);

  const handler = useHandler();
  const navigate = useNavigate();

  const { id } = useParams();

  const listStep = [
    {
      description: getLexicon?.['General Information'],
      icon: (active) => <RiInformationFill color={active ? '#4cdfd5' : '#4C638C'} size={48} />,
      title: getLexicon?.['Section 1'],
    },
    {
      description: getLexicon?.['Biophysical'],
      icon: (active) => <RiPulseFill color={active ? '#4cdfd5' : '#4C638C'} size={48} />,
      title: getLexicon?.['Section 2'],
    },
    {
      description: getLexicon?.['Social Safeguard'],
      icon: (active) => <RiShieldFill color={active ? '#4cdfd5' : '#4C638C'} size={48} />,
      title: getLexicon?.['Section 3'],
    },
  ]

  const currentForm = useMemo(() => {
    return getDetailEligibility?.filter(item => item?.step === getStep);
  }, [getDetailEligibility, getStep]);

  useEffect(() => {
    if (id && getStatusEligibility?.toLowerCase() !== 'submitted' && getStatusEligibility?.toLowerCase() !== 'finalized') {
      window.addEventListener('beforeunload', closeAlert);
    }

    return () => {
      window.removeEventListener('beforeunload', closeAlert);
    }
  }, [id, getStatusEligibility])

  useEffect(() => {
    handler.subToLanguage(setLanguage);
    handler.subToLexicon(setLexicon);

    return () => {
      handler.unsubFromLanguage(setLanguage);
      handler.unsubFromLexicon(setLexicon);
    }
  }, [handler])

  useEffect(() => {
    if (id && getInitialData) {
      const params = {
        eligibilityCheckId: id,
      }

      handler.requestOwnedEligibilityCheckDetailLookup(params)

      handler.subToOwnedEligibilityCheckDetail(res => {
        let field = res?.document;

        let currentStep = 1;
        const totalStep = Math.max(...defaultDetailEligibility.map(item => item.step));

        if (field) {
          if (res?.status?.toLowerCase() !== 'submitted' && res?.status?.toLowerCase() !== 'finalized') {
            for (let step = 1; step < totalStep; step++) {
              if (field?.filter(item => item?.step === step).every(item => (item?.required && item?.value !== '' && item?.value !== null) || !item?.required)) {
                if (step !== totalStep) currentStep = step + 1;
              }
            }
          }

          setStep(currentStep);

          setDetailEligibility(field);
          setStatusEligibility(res?.status);
        }
      })

      setInitialData(false);

      return () => handler.unsubFromOwnedEligibilityCheckDetail(setDetailEligibility)
    } else {
      setDetailEligibility(defaultDetailEligibility);

      setInitialData(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, id])

  const closeAlert = (e) => {
    e = e || window.event;

    if (e) e.returnValue = 'Sure?';

    return 'Sure?';
  }

  const submitDraft = async () => {
    const response = await handler.requestEligibilityCheckDrafting({
      id: id,
      document: getDetailEligibility,
    });

    if (response.ok) {
      if (!id) navigate(`/eligibility-check-submission/${response?.id}`);
    }
  }

  const onChange = (e) => {
    setDetailEligibility(prevState => {
      let currentValue = [...prevState];
      let index = currentValue.findIndex(item => item?.name === e?.target?.name);

      if (
        currentValue[index]?.type === 'number' ||
        currentValue[index]?.type === 'coordinate'
      ) {
        currentValue[index].value = +e.target.value
      } else {
        currentValue[index].value = e.target.value
      }

      return currentValue;
    });
  }

  const onChangeFile = async (e, name) => {
    const base64 = await base64FileEncoder(e);

    setDetailEligibility(prevState => {
      let currentValue = [...prevState];
      let index = currentValue.findIndex(item => item?.name === name);

      currentValue[index].value = {
        fileName: e?.name,
        base64: base64,
      };

      return currentValue;
    });

    return false;
  }

  const onChangeSelect = (value, name, isMultiple = false) => {
    setDetailEligibility(prevState => {
      let currentValue = [...prevState];
      let index = currentValue.findIndex(item => item?.name === name);

      if (isMultiple) {
        currentValue[index].value = value?.map(item => currentValue[index]?.options?.find(data => data?.value === item));
      } else {
        currentValue[index].value = currentValue[index]?.options?.find(item => item?.value === value);
      }

      return currentValue;
    });;
  }

  const onRemoveFile = async (name) => {
    setDetailEligibility(prevState => {
      let currentValue = [...prevState];
      let index = currentValue.findIndex(item => item?.name === name);

      currentValue[index].value = null;

      return currentValue;
    });

    return false;
  }

  const Submit = () => {
    handler.requestEligibilityCheckSubmission({
      id: id,
      document: getDetailEligibility,
    });
  }
  const isSmallScreen = useMediaQuery({ query: '(min-width: 640px)' })

  return (
    <Layout>
      <section className='min-h-[calc(100vh-164px)] max-w-[1440px] mx-auto px-14 overflow-hidden relative w-full'>
        <section className={`border-b border-slate-400 py-7 relative w-full z-[1]`}>
          <p className='font-bold leading-10 text-xl text-[#4C638C] tracking-[0.25px]'>{getLexicon?.['Eligibility Check']}</p>
          <p className='leading-6 text-[#6481b3] tracking-[0.5px]'>
            {getLexicon?.['Agriculture, Forestry, and Other Land Use']}
          </p>
        </section>
        <section className={`flex flex-col items-stretch min-h-[calc(100vh-323px)] w-full lg:flex-row`}>
          <aside className='border-b border-slate-400 hidden hide-scrollbar overflow-y-auto w-full md:divide-slate-400 md:divide-x-[1px] md:grid md:grid-cols-3 lg:border-r lg:divide-x-0 lg:flex lg:flex-col lg:max-w-[280px]'>
            {listStep.map((item, index) => {
              return (
                <button key={index} type='button' className='duration-300 flex gap-6 items-center pr-8 relative text-end transition-colors w-full' onClick={() => {
                  setStep(index + 1);

                  if (getStatusEligibility?.toLowerCase() !== 'submitted' && getStatusEligibility?.toLowerCase() !== 'finalized') submitDraft();
                }}>
                  <div className='flex-1 py-8 relative w-full lg:border-b lg:border-slate-400'>
                    <p className={`font-bold ${getStep - 1 === index ? 'text-[#4cdfd5]' : 'text-[#4C638C]'} text-xl`}>{item?.title}</p>
                    <p className='text-sm text-[#6481b3]'>{item?.description}</p>
                  </div>
                  <div className='h-12 relative w-12'>
                    {item?.icon(getStep - 1 === index)}
                  </div>
                  {getStep - 1 === index ?
                    <div className='absolute bg-[#4cdfd5] h-4 hidden items-center justify-center -right-2 rounded-full top-1/2 -translate-y-1/2 w-4 lg:flex'></div>
                    : null
                  }
                </button>
              )
            })}
          </aside>
          <section className='flex flex-1 flex-col relative'>
            <p className='block mt-6 ml-auto text-base text-[#6481b3] sm:hidden'>{getLexicon?.['Step']} {getStep} / {listStep?.length}</p>
            <section className='flex-1 overflow-y-auto pt-6 relative sm:pt-10 lg:px-10'>
              <Suspense fallback={
                <div className={`bg-transparent h-full w-full`}>
                  <div className="flex h-full items-center justify-center w-full">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
                  </div>
                </div>
              }>
                <div className='gap-2 grid items-start sm:grid-cols-2'>
                  {currentForm.filter(item => {
                    return item?.visibility === true || item?.visibility?.some(dataItem => dataItem?.value === currentForm?.find(data => data?.name === dataItem?.name)?.value?.value);
                  }).map((item, index) => {
                    const position = index + 1
                    const column = !isSmallScreen ? '' : position % 2 !== 0 ? 1 : 2
                    const labelRow = !isSmallScreen ? '' : column === 1 ? position : position - 1
                    const fieldRow = !isSmallScreen ? '' : labelRow + 1
                    return <>
                      <div
                        className={[
                          'flex',
                          'gap-1',
                          index === 0 || index === 1 ? '' : 'mt-3',
                        ].join(' ')}
                        style={{
                          gridRow: labelRow,
                          gridColumn: column,
                        }}
                      >
                        <div className={[
                          'flex',
                          'items-center',
                        ].join(' ')}>
                          <Typography.Title
                            key={`label_${index}`}
                            level={5}
                            className={[
                              'text-[#4C638C]',
                              'text-[0.9rem]',
                              'mb-0',
                            ].join(' ')}
                          >
                            {item?.label?.[getLanguage]} {item?.required ? <Typography.Text type='danger'>*</Typography.Text> : null}
                          </Typography.Title>
                        </div>
                        {item.information && <div className='flex items-center'>
                          <span
                            className="daisy-tooltip whitespace-pre-line"
                            data-tip={item.information[getLanguage]}
                          >
                            <RiInformationLine />
                          </span>
                        </div>}
                      </div>
                      {(() => {
                        if (item?.type === 'select') {
                          return (
                            <InputSelect
                              {...item}
                              key={index}
                              disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
                              label={item?.label?.[getLanguage]}
                              multiple={item?.multiple}
                              name={item?.name}
                              onChange={(value, name) => onChangeSelect(value, name, item?.multiple)}
                              options={item?.options?.map(option => ({ value: option?.value, label: option?.label?.[getLanguage] }))}
                              required={item?.required}
                              size='large'
                              value={item?.value}
                              style={{
                                gridRow: fieldRow,
                                gridColumn: column,
                              }}
                            />
                          )
                        } else if (item?.type === 'file') {
                          return (
                            <Upload
                              key={index}
                              accept={item?.accept}
                              action=""
                              className='test'
                              disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
                              listType="picture"
                              maxCount={1}
                              fileList={item?.value ? [{
                                id: index,
                                name: item?.value?.fileName,
                                status: 'done',
                                url: item?.value?.base64,
                                thumbUrl: item?.value?.base64,
                              }] : []}
                              beforeUpload={e => onChangeFile(e, item?.name)}
                              onRemove={() => onRemoveFile(item?.name)}
                              style={{
                                gridRow: fieldRow,
                                gridColumn: column,
                              }}
                            >
                              <Button className='flex items-center' icon={<UploadOutlined />} size='large'>{getLexicon?.['Upload']}</Button>
                            </Upload>
                          )
                        } else if (item?.type === 'textarea') {
                          return (
                            <InputText
                              {...item}
                              key={index}
                              disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
                              label={item?.label?.[getLanguage]}
                              name={item?.name}
                              onChange={onChange}
                              required={item?.required}
                              row={item?.row}
                              size='large'
                              type={item?.type}
                              value={item?.value}
                              style={{
                                gridRow: fieldRow,
                                gridColumn: column,
                              }}
                            />
                          )
                        } else if (item?.type === 'date') {
                          return (
                            <DatePicker
                              {...item}
                              key={index}
                              disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
                              placeholder={item?.label?.[getLanguage]}
                              name={item?.name}
                              onChange={(_, value) => onChange({
                                target: {
                                  name: item?.name,
                                  value,
                                }
                              })}
                              // required={item?.required}
                              value={!item?.value ? undefined : moment(item?.value, 'YYYY-MM-DD')}
                              size='large'
                              className={[
                                'bg-transparent',
                                '[&>input]:bg-transparent',
                                'placeholder:text-slate-400',
                                'disabled:text-slate-400',
                                'text-[#4C638C]',
                                '[&:not(:placeholder-shown)]:!border-[#4cdfd5]',
                              ].join(' ')}
                              style={{
                                gridRow: fieldRow,
                                gridColumn: column,
                              }}
                            />
                          )
                        } else if (item?.type === 'coordinate') {
                          return (
                            <InputCoordinate
                              style={{
                                gridRow: fieldRow,
                                gridColumn: column,
                              }}
                              getStatusEligibility={getStatusEligibility}
                              getLanguage={getLanguage}
                              onChange={onChange}
                              index={index}
                              item={item}
                            />
                          )
                        } else {
                          return (
                            <InputText
                              {...item}
                              key={index}
                              disabled={getStatusEligibility?.toLowerCase() === 'submitted'}
                              label={item?.label?.[getLanguage]}
                              name={item?.name}
                              onChange={onChange}
                              required={item?.required}
                              size='large'
                              type={item?.type}
                              value={item?.value}
                              style={{
                                gridRow: fieldRow,
                                gridColumn: column,
                              }}
                            />
                          )
                        }
                      })()}
                    </>
                  })}
                </div>
              </Suspense>
            </section>
            <footer className='flex gap-4 items-center justify-end mt-auto py-6 relative sm:flex-row sm:justify-between lg:px-10'>
              <p className='hidden text-base text-[#6481b3] sm:block'>{getLexicon?.['Step']} {getStep} / {listStep?.length}</p>
              <div className='flex items-center gap-4 relative'>
                {getStep <= 1 ?
                  null
                  :
                  <button type='button' className='bg-[#4cdfd5] px-8 py-3 rounded-full text-lg text-white' onClick={() => {
                    setStep(prevState => prevState - 1);

                    if (getStatusEligibility?.toLowerCase() !== 'submitted' && getStatusEligibility?.toLowerCase() !== 'finalized') submitDraft();
                  }}>{getLexicon?.back}</button>
                }
                {getStep >= listStep?.length ?
                  null
                  :
                  <button type='button' className='bg-[#4cdfd5] px-8 py-3 rounded-full text-lg text-white' onClick={() => {
                    setStep(prevState => prevState + 1);

                    if (getStatusEligibility?.toLowerCase() !== 'submitted' && getStatusEligibility?.toLowerCase() !== 'finalized') submitDraft();
                  }}>{getLexicon?.['Next']}</button>
                }
                {getStatusEligibility?.toLowerCase() !== 'submitted' && getStatusEligibility?.toLowerCase() !== 'finalized' ?
                  <button type='button' disabled={getDetailEligibility.some(item => item?.required && (item?.value === null || item?.value === undefined || item?.value === ''))} className='bg-[#4cdfd5] disabled:bg-slate-400 px-8 py-3 rounded-full text-lg text-white' onClick={Submit}>{getLexicon?.submit}</button>
                  :
                  null
                }
              </div>
            </footer>
          </section>
        </section>
      </section>
    </Layout >
  )
}
